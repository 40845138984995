import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

import "./contact.styles.scss";

import axios from "axios";
import { Formik } from "formik";

// Firebase
import db from "../Firebase/db";

// const accesorios = [
//   {
//     city: "Campeche",
//     mail: "mariana.flores@prominox.com.mx",
//   },
//   {
//     city: "Chiapas",
//     mail: "marisol.cantellano@prominox.com.mx",
//   },
//   {
//     city: "Ciudad de México",
//     mail: "marisol.cantellano@prominox.com.mx",
//   },
// ];

// const aluminio = [
//   {
//     city: "Campeche",
//     mail: "alfredo.lopez@prominox.mx",
//   },
//   {
//     city: "Chiapas",
//     mail: "alfredo.lopez@prominox.mx",
//   },
//   {
//     city: "Ciudad de México",
//     mail: "alfredo.lopez@prominox.mx",
//   },
// ];

const productsArray = {
  accesorios: [
    {
      city: "Aguascalientes",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Baja California",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Campeche",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "CDMX",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Chiapas",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Chihuahua",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Coahuila",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Colima",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Durango",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Estado de México",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Guanajuato",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Guerrero",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Hidalgo",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Jalisco",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Michoacán",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Morelos",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Nayarit",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Nuevo León",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Oaxaca",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Puebla",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Quéretaro",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Quintana Roo",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "San Luis Potosí",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Sinaloa",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Sonora",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Tabasco",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Tamaulipas",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Tlaxcala",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Veracruz",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Yucatán",
      mail: "roxanna.mktmax@gmail.com",
    },
    {
      city: "Zacatecas",
      mail: "roxanna.mktmax@gmail.com",
    },
  ],
  aluminio: [
    {
      city: "Aguascalientes",
      // mail: "erwin.ayvar@prominox.mx",
      mail: "ashanti.marketingmaximo@gmail.com",
    },
    {
      city: "Baja California",
      mail: "roxanne.marg@gmail.com",
      // mail: "roxanna@marketingmaximo.com.mx",
    },
    {
      city: "Baja California Sur",
      mail: "roxanne.marg@gmail.com",
      // mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Campeche",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "CDMX",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Chiapas",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Chihuahua",
      mail: "roxanne.marg@gmail.com",
    },
    {
      city: "Coahuila",
      mail: "roxanne.marg@gmail.com",
    },
    {
      city: "Colima",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Durango",
      mail: "roxanne.marg@gmail.com",
    },
    {
      city: "Estado de México",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Guanajuato",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Guerrero",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Hidalgo",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Jalisco",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Michoacán",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Morelos",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Nayarit",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Nuevo León",
      mail: "roxanne.marg@gmail.com",
    },
    {
      city: "Oaxaca",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Puebla",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Quéretaro",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Quintana Roo",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "San Luis Potosí",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Sinaloa",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Sonora",
      mail: "ashanti.marketingmaximo@gmail.com",
    },
    {
      city: "Tabasco",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Tamaulipas",
      mail: "roxanne.marg@gmail.com",
    },
    {
      city: "Tlaxcala",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Veracruz",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Yucatán",
      mail: "ashanti.socialmedia@gmail.com",
    },
    {
      city: "Zacatecas",
      mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
  ],
  acero: [
    {
      city: "Aguascalientes",
      mail: "pruebaricardo.va@gmail.com",
    },
    {
      city: "Baja California",
      mail: "pruebamarco.ant@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "pruebajulio.osuna@gmail.com",
    },
    {
      city: "Campeche",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "CDMX",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Chiapas",
      mail: "pruebasusana.ram@gmail.com",
    },
    {
      city: "Chihuahua",
      mail: "pruebacarlos.sama@gmail.com",
    },
    {
      city: "Coahuila",
      mail: "pruebaalondra.1@gmail.com",
    },
    {
      city: "Colima",
      mail: "pruebajonathan.tapia@gmail.com",
    },
    {
      city: "Durango",
      mail: "pruebaalondra.1@gmail.com",
    },
    {
      city: "Estado de México",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Guanajuato",
      mail: "pruebajosel.munoz@gmail.com",
    },
    {
      city: "Guerrero",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Hidalgo",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Jalisco",
      mail: "pruebajonathan.tapia@gmail.com",
    },
    {
      city: "Michoacán",
      mail: "pruebaj.remuco@gmail.com",
    },
    {
      city: "Morelos",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Nayarit",
      mail: "pruebajonathan.tapia@gmail.com",
    },
    {
      city: "Nuevo León",
      mail: "pruebal.nelly@gmail.com",
    },
    {
      city: "Oaxaca",
      mail: "pruebapaniagua.jonathan@gmail.com",
    },
    {
      city: "Puebla",
      mail: "pruebapaniagua.jonathan@gmail.com",
    },
    {
      city: "Quéretaro",
      mail: "pruebahilda.san@gmail.com",
    },
    {
      city: "Quintana Roo",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "San Luis Potosí",
      mail: "pruebajavier.cano@gmail.com",
    },
    {
      city: "Sinaloa",
      mail: "pruebaluis.ayala@gmail.com",
    },
    {
      city: "Sonora",
      mail: "pruebamarco.ant@gmail.com",
    },
    {
      city: "Tabasco",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "Tamaulipas",
      mail: "pruebaale.guada@gmail.com",
    },
    {
      city: "Tlaxcala",
      mail: "pruebapaniagua.jonathan@gmail.com",
    },
    {
      city: "Veracruz",
      mail: "pruebasusana.ram@gmail.com",
    },
    {
      city: "Yucatán",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "Zacatecas",
      mail: "pruebaricardo.va@gmail.com",
    },
  ],
  tuberia: [
    {
      city: "Aguascalientes",
      mail: "pruebaricardo.va@gmail.com",
    },
    {
      city: "Baja California",
      mail: "pruebamarco.ant@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "pruebajulio.osuna@gmail.com",
    },
    {
      city: "Campeche",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "CDMX",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Chiapas",
      mail: "pruebasusana.ram@gmail.com",
    },
    {
      city: "Chihuahua",
      mail: "pruebacarlos.sama@gmail.com",
    },
    {
      city: "Coahuila",
      mail: "pruebaalondra.1@gmail.com",
    },
    {
      city: "Colima",
      mail: "pruebajonathan.tapia@gmail.com",
    },
    {
      city: "Durango",
      mail: "pruebaalondra.1@gmail.com",
    },
    {
      city: "Estado de México",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Guanajuato",
      mail: "pruebajosel.munoz@gmail.com",
    },
    {
      city: "Guerrero",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Hidalgo",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Jalisco",
      mail: "pruebajonathan.tapia@gmail.com",
    },
    {
      city: "Michoacán",
      mail: "pruebaj.remuco@gmail.com",
    },
    {
      city: "Morelos",
      mail: "pruebaileana.gar@gmail.com",
    },
    {
      city: "Nayarit",
      mail: "pruebajonathan.tapia@gmail.com",
    },
    {
      city: "Nuevo León",
      mail: "pruebal.nelly@gmail.com",
    },
    {
      city: "Oaxaca",
      mail: "pruebapaniagua.jonathan@gmail.com",
    },
    {
      city: "Puebla",
      mail: "pruebapaniagua.jonathan@gmail.com",
    },
    {
      city: "Quéretaro",
      mail: "pruebahilda.san@gmail.com",
    },
    {
      city: "Quintana Roo",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "San Luis Potosí",
      mail: "pruebajavier.cano@gmail.com",
    },
    {
      city: "Sinaloa",
      mail: "pruebaluis.ayala@gmail.com",
    },
    {
      city: "Sonora",
      mail: "pruebamarco.ant@gmail.com",
    },
    {
      city: "Tabasco",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "Tamaulipas",
      mail: "pruebaale.guada@gmail.com",
    },
    {
      city: "Tlaxcala",
      mail: "pruebapaniagua.jonathan@gmail.com",
    },
    {
      city: "Veracruz",
      mail: "pruebasusana.ram@gmail.com",
    },
    {
      city: "Yucatán",
      mail: "pruebamiguel.ag@gmail.com",
    },
    {
      city: "Zacatecas",
      mail: "pruebaricardo.va@gmail.com",
    },
  ],
};
const productsFinalArray = {
  accesorios: [
    {
      city: "Aguascalientes",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Baja California Sur",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Campeche",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Colima",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Durango",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "mariana.flores@prominox.mx",
    },
  ],
  aluminio: [
    {
      city: "Aguascalientes",
      // mail: "erwin.ayvar@prominox.mx",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "ivonne.carrillo@prominox.mx",
      // mail: "roxanna@marketingmaximo.com.mx",
    },
    {
      city: "Baja California Sur",
      mail: "ivonne.carrillo@prominox.mx",
      // mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Campeche",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "ivonne.carrillo@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "ivonne.carrillo@prominox.mx",
    },
    {
      city: "Colima",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Durango",
      mail: "ivonne.carrillo@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "ivonne.carrillo@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "ivonne.carrillo@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "manuel.mendoza@prominox.mx",
    },
  ],
  acero: [
    {
      city: "Aguascalientes",
      mail: "ricardo.vazquez@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "douglas.kittrell@prominox.mx",
    },
    {
      city: "Baja California Sur",
      mail: "julio.osuna@prominox.mx",
    },
    {
      city: "Campeche",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "victor.villalobos@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "alondra.sotomayor@prominox.mx",
    },
    {
      city: "Colima",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Durango",
      mail: "alondra.sotomayor@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "jose.munoz@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "jonathan.mendez@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "nelly.alvarez@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "hilda.sanchez@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "samuel.orozco@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "luis.ayala@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "douglas.kittrell@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "ximena.martinez@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "ricardo.vazquez@prominox.mx",
    },
  ],
  tuberia: [
    {
      city: "Aguascalientes",
      mail: "ricardo.vazquez@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "marco.meza@prominox.mx",
    },
    {
      city: "Baja California Sur",
      mail: "julio.osuna@prominox.mx",
    },
    {
      city: "Campeche",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "carlos.samaniego@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "alondra.sotomayor@prominox.mx",
    },
    {
      city: "Colima",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Durango",
      mail: "alondra.sotomayor@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "jose.munoz@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "jonathan.mendez@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "ileana.garcia@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "nelly.alvarez@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "hilda.sanchez@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "javier.cano@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "luis.ayala@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "marco.meza@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "maria.alejandre@prominox.mx, ximena.martinez@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "miguel.aguilar@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "ricardo.vazquez@prominox.mx",
    },
  ],
};

const products2Array = {
  accesorios: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
  aluminio: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
  acero: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
  tuberia: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
};

const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

const products3Obj = {
  dallas: [
    {
      // mail: "greg@prominox-usa.com",
      // secondaryMail: "edwin@prominox-usa.com",
      mail: "rodrigo.zenteno7@gmail.com",
      office: "Dallas",
    },
  ],
  houston: [
    {
      mail: "rodrigo.zenteno7@gmail.com",
      // mail: "steph@prominox-usa.com",
      // secondaryMail: "rosa@prominox-usa.com",
      office: "Houston",
    },
  ],
};
export default function Contact() {
  // const [inputs, setInputs] = useState();
  const [product, setProduct] = useState("");
  const [city, setCity] = useState();
  const [office, setOffice] = useState();
  // const [product, setProduct] = useState("");
  const [sendTo, setSendTo] = useState();
  const [sendToSecondary, setSendToSecondary] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // function handleChange(e) {
  //   let { value, name } = e.target;
  //   setInputs({
  //     ...inputs,
  //     [name]: value,
  //   });
  // }
  // function handleProductChange(val) {
  //   // let { value, name } = e.target;
  //   setProduct(val);
  //   value = val;
  // }
  function handleCityChange(e) {
    let { value, name } = e.target;
    setCity(value);
  }
  const selectedArray = [];
  const citiesArray = [];
  // if (product === "accesorios") {
  //   console.log(productsArray.accesorios);
  // }
  // switch (product) {
  //   case "accesorios-para-barandales":
  //     productsFinalArray.accesorios.map((product) =>
  //       selectedArray.push(product)
  //     );
  //     selectedArray.map((sel) => citiesArray.push(sel.city));
  //     break;
  //   case "aluminio":
  //     productsFinalArray.aluminio.map((product) => selectedArray.push(product));
  //     selectedArray.map((sel) => citiesArray.push(sel.city));
  //     break;
  //   case "acero-inoxidable":
  //     productsFinalArray.acero.map((product) => selectedArray.push(product));
  //     selectedArray.map((sel) => citiesArray.push(sel.city));
  //     break;
  //   case "tubería-conduit":
  //     productsFinalArray.tuberia.map((product) => selectedArray.push(product));
  //     selectedArray.map((sel) => citiesArray.push(sel.city));
  //     break;

  //   default:
  //     break;
  // }
  switch (office) {
    case "Dallas":
      products3Obj.dallas.map((product) => selectedArray.push(product));
      selectedArray.map((sel) => citiesArray.push(sel.city));
      break;
    case "Houston":
      products3Obj.houston.map((product) => selectedArray.push(product));
      selectedArray.map((sel) => citiesArray.push(sel.city));
      break;

    default:
      break;
  }
  // console.log(selectedArray);
  // console.log(citiesArray);
  const currentCity = selectedArray.filter((m) => m.office === office);
  console.log(currentCity);
  useEffect(() => {
    if (currentCity.length > 0) {
      const { mail, secondaryMail } = currentCity[0];
      setSendTo(mail);
      setSendToSecondary(secondaryMail);
    }
  }, [office]);
  // console.log(currentCity[0]);
  // setSendTo(currentCity[0]);
  // console.log(sendTo);
  // console.log(sendTo);
  // if (currentCity.length > 0) {
  //   const { mail } = currentCity[0];
  //   setSendTo(mail);
  //   console.log(mail);
  // }

  // function onSubmit(e) {
  //   const { clientEmail, clientName, clientPhone, clientMessage } = inputs;
  //   e.preventDefault();
  //   // console.log(city);
  //   // console.log(inputs);
  //   axios
  //     .post("http://prominox.com.mx/api/email", {
  //       // .post("http://localhost:1337/email", {
  //       to: [
  //         `${sendTo}`,
  //         "erwin.ayvar@prominox.mx",
  //         "daniela.patino@prominox.mx",
  //         "rodrigo.zenteno7@gmail.com",
  //         "ashanti@marketingmaximo.com.mx",
  //       ],
  //       // to: "rodrigo.zenteno7@gmail.com",
  //       subject: "Nuevo mensaje de forma de contacto en Prominox",
  //       html: `<h1>${clientName} envió un mensaje </h1> <p>Estos son sus datos de contacto:</p> <ul><li>Correo de contacto: ${clientEmail}</li><li>Teléfono: ${clientPhone}</li><li>Está interesado/a en: ${product}</li><li>Ciudad: ${city}</li></ul><p>Mensaje: ${clientMessage}</p>`,
  //       // html: `<html lang="es"><head><meta charset="UTF-8" /><meta http-equiv="X-UA-Compatible" content="IE=edge" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><link rel="preconnect" href="https://fonts.gstatic.com" /><link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Raleway&display=swap" rel="stylesheet"/></head><body style="margin: 0; padding: 0; background-color: #ddd; color: #000;"><div style="font-family: 'Raleway', sans-serif; background-color: #ddd; padding: 1rem; display: flex; justify-content: center; height: 100%;"> <div style="width: 80%; height: 400px; margin: auto; background-color: #fff; border-bottom: 15px solid #6D1E39; padding: 2.5rem;"><h2 style="text-transform: uppercase; font-family: 'Montserrat', sans-serif; font-weight: 600; margin-bottom: 1rem;">${formData.clientName} envió un mensaje</h2><p style="margin-bottom: 1rem">Estos son sus datos de contacto:</p><ul><li>Correo de contacto: ${formData.email}</li><li>Teléfono: ${formData.phone}</li><li>Está interesado/a en: ${data.NombrePropiedad}</li></ul><img src="https://clusterdelcentro.com/logo.png" alt="Logo Cluster del Centro" style="display: block; margin: 0 auto; width: 120px; height: auto;"/></div></div></body></html>`,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   // db.collection("mail")
  //   //   .add({
  //   //     to: `${sendTo}`,
  //   //     message: {
  //   //       subject: "Nuevo mensaje de página web PROMINOX",
  //   //       html: `<h1>${clientName} envió un mensaje</h1> <p>Estos son sus datos de contacto:</p> <ul><li>Correo de contacto: ${clientEmail}</li><li>Teléfono: ${clientPhone}</li></ul> <p>Está interesada/o en: ${product} </p> <p>Mensaje: ${clientMessage}</p>`,
  //   //     },
  //   //   })
  //   //   .then(() => console.log("Queued email for delivery!"));
  //   e.target.reset();
  // }
  // if (product === "accesorios") {
  //   setCity(accesorios);
  //   console.log(city);
  // } else {
  //   console.log("hola");
  // }
  return (
    <section id="contact" className="contact">
      <Container>
        {success ? (
          <Alert variant="success" className="text-center">
            Your quotation was sent successfully. Soon, one of our agents will
            get in touch with you.
          </Alert>
        ) : null}
        {error ? (
          <Alert variant="danger" className="text-center">
            There was an error with your submission. Please try again later.
          </Alert>
        ) : null}
        <h2 className="wow fadeInDown">request to quote</h2>
        <div
          className="contact__form__container wow fadeInDown"
          data-wow-delay=".5s"
        >
          <Formik
            initialValues={{
              clientName: "",
              selectedProduct: "",
              office: "",
              state: "",
              clientEmail: "",
              clientPhone: 0,
              clientMessage: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.clientEmail) {
                errors.clientEmail = "Email field is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.clientEmail
                )
              ) {
                errors.clientEmail = "Invalid email address";
              }
              // if (!values.companyName) {
              //   errors.companyName = "Company Name field is required";
              // }
              if (!values.clientName) {
                errors.clientName = "Customer name field is required";
              }
              if (!values.office) {
                errors.office = "Office field is required";
              }
              if (!values.selectedProduct) {
                errors.selectedProduct = "A product needs to be selected";
              }
              if (values.clientPhone === 0) {
                errors.clientPhone = "Phone number is required";
              }
              if (values.state === "") {
                errors.state = "Location field is required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log(values);
              const {
                clientEmail,
                clientMessage,
                clientName,
                office,
                clientPhone,
                selectedProduct,
              } = values;
              setLoading(true);
              db.collection("mail")
                .add({
                  to: [
                    `${sendTo}`,
                    `${sendToSecondary}`,
                    "erwin.ayvar@prominox.mx",
                    // "ashanti@marketingmaximo.com.mx",
                    // "ruben@marketingmaximo.com.mx",
                    // "daniela.patino@prominox.mx",
                    // "rodrigo.zenteno7@gmail.com",
                    // "contact@quintech.com.mx",
                  ],
                  message: {
                    subject: "New quotation from Prominox USA website",
                    text: `<h1>${clientName} sent a quotation </h1> <p>These are the contact details:</p> <ul><li>Email: ${clientEmail}</li><li>Phone number: ${clientPhone}</li><li>She/he is interested in: ${product}</li><li>City: ${city}</li><li>Office: ${office}</li></ul><p>Additional information: ${clientMessage}</p>`,
                    html: `<h1>${clientName} sent a quotation </h1> <p>These are the contact details:</p> <ul><li>Email: ${clientEmail}</li><li>Phone number: ${clientPhone}</li><li>She/he is interested in: ${product}</li><li>City: ${city}</li><li>Office: ${office}</li></ul><p>Additional information: ${clientMessage}</p>`,
                  },
                })
                .then(() => {
                  setSuccess(true);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 4000);
                  setLoading(false);
                })
                .catch(function (error) {
                  setLoading(false);
                  setError(true);
                  setTimeout(() => {
                    setError(false);
                  }, 4000);
                  console.log(error);
                });
              // axios
              //   // .post("http://prominox.com.mx/api/email", {
              //   .post("http://prominox.com.mx/api/email", {
              //     to: [
              //       `${sendTo}`,
              //       // "erwin.ayvar@prominox.mx",
              //       // "ashanti@marketingmaximo.com.mx",
              //       // "ruben@marketingmaximo.com.mx",
              //       // "daniela.patino@prominox.mx",
              //       // "rodrigo.zenteno7@gmail.com",
              //       // "contact@quintech.com.mx",
              //     ],
              //     subject: `New quotation from Prominox USA (${office}) website`,
              //     html: `<h1>${clientName} sent a quotation for office in ${office} </h1> <p>These are the contact details:</p> <ul><li>Email: ${clientEmail}</li><li>Phone Number: ${clientPhone}</li><li>She/he is interested in: ${selectedProduct}</li><li>City: ${city}</li></ul><p>Additional information: ${clientMessage.replace(
              //       /[\n]/g,
              //       "<br>"
              //     )}</p>`,
              //   })
              //   .then((res) => {
              //     console.log(res);
              //     setLoading(false);
              //     setSuccess(true);
              //     setTimeout(() => {
              //       setSuccess(false);
              //     }, 4000);
              //     // history.push(`/gracias-${product}`);
              //   })
              //   .catch(function (error) {
              //     setLoading(false);
              //     setError(true);
              //     setTimeout(() => {
              //       setError(false);
              //     }, 4000);
              //     console.log(error);
              //   });
              resetForm({
                clientName: "",
                office: "",
                state: "",
                clientEmail: "",
                selectedProduct: "",
                clientPhone: 0,
                clientMessage: "",
              });
              setCity("");
              setOffice("");
              setProduct("");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} id="contact-form">
                <Form.Group controlId="formGroupName">
                  <Form.Label>Contact *</Form.Label>
                  <Form.Control
                    type="text"
                    name="clientName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientName}
                  />
                  <span>
                    {errors.clientName &&
                      touched.clientName &&
                      errors.clientName}
                  </span>
                </Form.Group>
                {/* <Form.Group controlId="formGroupName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                  />
                  <span>
                    {errors.companyName &&
                      touched.companyName &&
                      errors.companyName}
                  </span>
                </Form.Group> */}
                <Form.Group controlId="formGroupEmail">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    name="clientEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientEmail}
                  />
                  <span>
                    {errors.clientEmail &&
                      touched.clientEmail &&
                      errors.clientEmail}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupPhone">
                  <Form.Label>Phone *</Form.Label>
                  <Form.Control
                    type="number"
                    name="clientPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientPhone}
                  />
                  <span>
                    {errors.clientPhone &&
                      touched.clientPhone &&
                      errors.clientPhone}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupProduct">
                  <Form.Label>Product *</Form.Label>
                  <Form.Control
                    as="select"
                    name="selectedProduct"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setProduct(e.target.value);
                      values.selectedProduct = e.target.value;
                    }}
                    onBlur={handleBlur}
                    value={product}
                  >
                    <option>Select an option...</option>
                    <option value="Aluminum">Aluminum</option>
                    <option value="Stainless steel">Stainless steel</option>
                    <option value="Conduit pipe">Conduit pipe</option>
                  </Form.Control>
                  <span>
                    {touched.selectedProduct && errors.selectedProduct}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupProduct">
                  <Form.Label>office *</Form.Label>
                  <Form.Control
                    as="select"
                    name="office"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setOffice(e.target.value);
                      values.office = e.target.value;
                    }}
                    onBlur={handleBlur}
                    value={office}
                  >
                    <option>Select an office...</option>
                    <option value="Dallas">Dallas</option>
                    <option value="Houston">Houston</option>
                  </Form.Control>
                  <span>
                    {errors.office && touched.office && errors.office}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupState">
                  <Form.Label>location *</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setCity(e.target.value);
                      values.state = e.target.value;
                    }}
                    onBlur={handleBlur}
                    value={city}
                  >
                    <option>Select an option...</option>
                    {states.map((state, key) => (
                      <option key={key}>{state.name}</option>
                    ))}
                  </Form.Control>
                  <span>{errors.state && touched.state && errors.state}</span>
                </Form.Group>
                {/*<Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>¿Donde te encuentras?</Form.Label>
                  <Form.Control
                    as="select"
                    name="city"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setCity(e.target.value);
                      values.city = e.target.value;
                    }}
                    onBlur={handleBlur}
                    value={city}
                    required
                  >
                    <option>Seleccionar estado...</option>
                    {citiesArray.map((city, key) => (
                      <option value={`${city}`} key={key}>
                        {city}
                      </option>
                    ))}
                  </Form.Control>
                  <span>{errors.city && touched.city && errors.city}</span>
                </Form.Group> */}
                <Form.Group controlId="formGroupPhone">
                  <Form.Label>Additional information:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="clientMessage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientMessage}
                  />
                  <span>
                    {errors.clientMessage &&
                      touched.clientMessage &&
                      errors.clientMessage}
                  </span>
                </Form.Group>
                <button type="submit" className="form-send">
                  {loading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    "Send"
                  )}
                </button>
              </Form>
              //  <form onSubmit={handleSubmit}>
              //    <input
              //      type="email"
              //      name="email"
              //      onChange={handleChange}
              //      onBlur={handleBlur}
              //      value={values.email}
              //    />
              //    {errors.email && touched.email && errors.email}
              //    <input
              //      type="password"
              //      name="password"
              //      onChange={handleChange}
              //      onBlur={handleBlur}
              //      value={values.password}
              //    />
              //    {errors.password && touched.password && errors.password}
              //    <button type="submit" disabled={isSubmitting}>
              //      Submit
              //    </button>
              //  </form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}
