import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";

import "./home.styles.scss";
import logoUrl from "../../assets/prominox-logo--white.png";
import aceroUrl from "../../assets/img-home-acero.png";
import anguloUrl from "../../assets/icono-angulo.png";
// import aluminioUrl from "../../assets/img-home-aluminio.png";
import tubingUrl from "../../assets/icono-tubing.png";
// import tuberiaUrl from "../../assets/img-home-tuberia.png";
import barUrl from "../../assets/icono-bar.png";
import laminaUrl from "../../assets/icono-lamina.png";
// import barandalUrl from "../../assets/img-home-barandal.png";
// import experienciaUrl from "../../assets/experiencia.svg";
// import experienciaUrl2 from "../../assets/experiencia.jpg";
// import certificacionUrl from "../../assets/certificacion.svg";
// import certificacionUrl2 from "../../assets/certificacion.jpg";
// import nacionalUrl from "../../assets/nacional.svg";
// import nacionalUrl2 from "../../assets/nacional.jpg";
// import centroUrl from "../../assets/centro.svg";
// import centroUrl2 from "../../assets/centro.jpg";
// import aceroProductoUrl from "../../assets/img-productos-acero.png";
// import alumninioProductoUrl from "../../assets/img-producto-aluminio.png";
// import tuberiaProductoUrl from "../../assets/img-producto-tuberia.png";
// import barandalesProductoUrl from "../../assets/img-producto-barandal.png";
// import arrowDownUrl from "../../assets/arrow_down-icon--white.svg";
// import mapIconUrl from "../../assets/map-mexico.png";
import customIconUrl from "../../assets/custom-icon--white.svg";
import qualityIconUrl from "../../assets/quality-icon--white.svg";
import polishingIconUrl from "../../assets/lamina-300x151.png";
// import attentionIconUrl from "../../assets/attention-icon--white.svg";
import requirementIconUrl from "../../assets/requirements-icon--white.svg";
import trustIconUrl from "../../assets/trust-icon--white.svg";
import oneStopIconUrl from "../../assets/icn-home9.png";
// import coverageIconUrl from "../../assets/coverage-icon--white.svg";
import deliverytIconUrl from "../../assets/delivery-icon--white.svg";
import inventoryIconUrl from "../../assets/inventory-icon--white.svg";
import autoIconUrl from "../../assets/auto-icon--white.png";
// import planeIconUrl from "../../assets/plane-icon--white.png";
import buildingIconUrl from "../../assets/building-icon--white.png";
import kitchenIconUrl from "../../assets/kitchen-icon--white.png";
import transportIconUrl from "../../assets/transport-icon--white.png";
import homeIconUrl from "../../assets/home-icon--white.png";
import furnitureIconUrl from "../../assets/furniture-icon--white.png";
import homeElectroIconUrl from "../../assets/home-electro-icon--white.png";
import oilIconUrl from "../../assets/oil-icon--white.png";
import mineyIconUrl from "../../assets/minery-icon--white.png";
import gasIconUrl from "../../assets/gas-icon--white.png";
import electricIconUrl from "../../assets/electric-icon--white.png";
import Contact from "../Contact/Contact";

import HomeVideo from "../../assets/videos/HOME.mp4";

const Home = () => {
  return (
    <div>
      <Jumbotron fluid className="jumbotron--hero">
        <video autoPlay loop muted className="video">
          <source src={HomeVideo} type="video/mp4" />
        </video>
        <Container fluid>
          <h1 className="wow fadeInUp">prominox usa</h1>
          <h1 className="wow fadeInUp" data-wow-delay="1s">
            We are a company which has been dedicated for more than 30 years to
            the distribution of
          </h1>
          <h2 className="wow fadeInUp" data-wow-delay="1.2s">
            stainless steel
          </h2>
          <Row className="wow fadeInUp" data-wow-delay="1.5s">
            <Col xs={6} lg={"auto"}>
              <img src={aceroUrl} alt="Prominox USA. Sheet/plate" />
              <p>Sheet/plate</p>
            </Col>
            <Col xs={6} lg={"auto"}>
              <img src={anguloUrl} alt="Prominox USA. Structural" />
              <p>Structural</p>
            </Col>
            <Col xs={6} lg={"auto"}>
              <img src={tubingUrl} alt="Prominox USA. Tubing" />
              <p>Tubing</p>
            </Col>
            <Col xs={6} lg={"auto"}>
              <img src={barUrl} alt="Prominox USA. Bar" />
              <p>Bar</p>
            </Col>
            <Col xs={6} lg={"auto"}>
              <img src={laminaUrl} alt="Prominox USA. Aluminum" />
              <p>Aluminum</p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      {/* <section className="about-us">
        <Container>
          <h2 className="wow fadeInDown">why choose prominox usa?</h2>
          <Row className="wow fadeInUp" data-wow-delay=".6s">
            <Col xs={12} lg={6}>
              <div className="about-us__card">
                <img src={experienciaUrl} alt="Experiencia en el mercado" />
                <div className="about-us__card--body">
                  <h5>+30 años de experiencia</h5>
                  <p>
                    Prominox es una empresa líder en la distribución de acero
                    inoxidable, aluminio, tubería Conduit y accesorios para
                    barandales. Empresa mexicana fundada en 1989, la cual ha
                    crecido con solidez, innovación y excelencia en productos,
                    servicios y total compromiso con nuestros clientes.
                  </p>
                </div>
              </div>
              <div className="about-us__card">
                <img src={nacionalUrl} alt="Cobertura Nacional" />
                <div className="about-us__card--body">
                  <h5>cobertura nacional</h5>
                  <p>
                    Red de 18 sucursales ubicadas estratégicamente en las
                    ciudades más importantes a lo largo del país
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} className="mt-3 mt-md-0">
              <div className="about-us__card">
                <img src={certificacionUrl} alt="Certificación INTERTEK" />
                <div className="about-us__card--body">
                  <h5>certificación intertek</h5>
                </div>
              </div>
              <div className="about-us__card" style={{ marginTop: "2.3rem" }}>
                <img src={centroUrl} alt="Centros de servicio" />
                <div className="about-us__card--body">
                  <h5>centro de servicio</h5>
                  <p>Planta de 1er Nivel</p>
                  <p>Maquinaria de última generación</p>
                  <p>Líneas de producción</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="products">
        <Container>
          <h3 className="wow fadeInDown">nuestros productos</h3>
          <Row className="wow fadeInUp" data-wow-delay="1s">
            <Col xs={12} lg={3} className="mt-4 mt-md-0">
              <div className="product__card">
                <img
                  src={aceroProductoUrl}
                  alt="Producto Prominox - Acero Inoxodable"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>acero inoxidable</h5>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div className="product__card">
                <img
                  src={alumninioProductoUrl}
                  alt="Producto Prominox - Aluminio"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>aluminio</h5>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div className="product__card">
                <img
                  src={tuberiaProductoUrl}
                  alt="Producto Prominox - Tubería Conduit"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>tubería conduit</h5>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div className="product__card">
                <img
                  src={barandalesProductoUrl}
                  alt="Producto Prominox - Accesorios para Barandales"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>accesorios para barandales</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="coverage">
        <Container>
          <h4 className="wow fadeInDown">cobertura nacional</h4>
          <img
            className="wow fadeInUp"
            data-wow-delay=".5s"
            src={mapIconUrl}
            alt="PROMINOX cobertura nacional"
          />
          <div className="coverage__cities wow fadeInUp" data-wow-delay=".7s">
            <ul>
              <li>aguascalientes</li>
              <li>chihuahua</li>
              <li>culiacán</li>
              <li>guadalajara</li>
              <li>iztapalapa</li>
              <li>león</li>
              <li>mazatlán</li>
              <li>mérida</li>
              <li>monterrey</li>
              <li>morelia</li>
              <li>puebla</li>
              <li>querétaro</li>
              <li>reynosa</li>
              <li>san luis potosí</li>
              <li>tijuana</li>
              <li>tlalnepantla</li>
              <li>torreón</li>
              <li>veracruz</li>
            </ul>
          </div>
        </Container>
      </section> */}
      <section className="why-us">
        <Container>
          <h5 className="wow fadeInDown">why choose prominox usa?</h5>
          <Row className="wow fadeInUp" data-wow-delay=".7s">
            <Col xs={12} lg={6}>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={customIconUrl} alt="Producto a la medida" />
                </div>
                <p>Competitive Pricing</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={qualityIconUrl} alt="Calidad del producto" />
                </div>
                <p>
                  One stop shopping with our extensive inventory under one roof
                </p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={polishingIconUrl} alt="Atención personalizada" />
                </div>
                <p>Polishing Line for special finishes</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img
                    src={requirementIconUrl}
                    alt="Requerimientos especiales"
                  />
                </div>
                <p>J.I.T Programs</p>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="why-us__individual">
                <div>
                  <img src={deliverytIconUrl} alt="Entrega a tiempo" />
                </div>
                <p>Same day or next day delivery</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={trustIconUrl} alt="Proveedor confiable" />
                </div>
                <p>CTL line for special lengths</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={inventoryIconUrl} alt="Inventario de producto" />
                </div>
                <p>Stock and release</p>
              </div>
              {/* <div className="why-us__individual mt-0 mt-lg-4">
                <div>
                  <img
                    src={oneStopIconUrl}
                    alt="Cobertura de distribución nacional"
                  />
                </div>
                <p>
                  One stop shopping with our extensive inventory under one roof
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="industrial-sectors">
        <Container fluid>
          <h5 className="wow fadeInDown">industry sectors</h5>
          <div
            className="industrial-sectors__grid wow fadeInUp"
            data-wow-delay=".7s"
          >
            <div className="industrial-sectors__grid__container">
              <div className="industrial-sectors__grid--aero">
                <div>
                  <img src={logoUrl} alt="Sector Aeroespacial" />
                </div>
              </div>
              <div className="industrial-sectors__grid--auto">
                <div>
                  <img src={autoIconUrl} alt="Sector Automotriz" />
                </div>
                <p>automotive</p>
              </div>
              <div className="industrial-sectors__grid--build">
                <div>
                  <img src={buildingIconUrl} alt="Sector Construcción" />
                </div>
                <p>architectural</p>
              </div>
              <div className="industrial-sectors__grid--food">
                <div>
                  <img src={kitchenIconUrl} alt="Sector Alimenticio" />
                </div>
                <p>kitchen equipment</p>
              </div>
            </div>
            <div className="industrial-sectors__grid__container">
              <div className="industrial-sectors__grid--transport">
                <div>
                  <img src={transportIconUrl} alt="Sector del Transporte" />
                </div>
                <p>transportation</p>
              </div>
              <div className="industrial-sectors__grid--home">
                <div>
                  <img src={homeIconUrl} alt="Sector Home Appliance" />
                </div>
                <p>home appliances</p>
              </div>
              <div className="industrial-sectors__grid--furniture">
                <div>
                  <img src={furnitureIconUrl} alt="Muebles" />
                </div>
                <p>HVAC</p>
              </div>
              <div className="industrial-sectors__grid--electro">
                <div>
                  <img src={homeElectroIconUrl} alt="Electrodoméstico" />
                </div>
                <p>household appliances</p>
              </div>
            </div>
            <div className="industrial-sectors__grid__container">
              <div className="industrial-sectors__grid--gob">
                <div>
                  <img src={oilIconUrl} alt="Sector Automotriz" />
                </div>
                <p>government</p>
              </div>
              <div className="industrial-sectors__grid--minery">
                <div>
                  <img src={mineyIconUrl} alt="Sector Aeroespacial" />
                </div>
                <p>conveyors</p>
              </div>
              <div className="industrial-sectors__grid--gas">
                <div>
                  <img src={gasIconUrl} alt="Sector Construcción" />
                </div>
                <p>oil and gas</p>
              </div>
              <div className="industrial-sectors__grid--electric">
                <div>
                  <img src={electricIconUrl} alt="Sector Alimenticio" />
                </div>
                <p>electrical</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Contact />
      {/* <section className="contact">
        <Container>
          <h2>solicita tu cotización</h2>
          <div
            className="contact__form__container"
            style={{ backgroundColor: "#fff" }}
          >
            <Form>
              <Form.Group controlId="formGroupName">
                <Form.Label>Nombre completo</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control type="email" />
              </Form.Group>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control type="number" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>¿Donde te encuentras?</Form.Label>
                <Form.Control as="select">
                  <option>Seleccionar estado...</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupProduct">
                <Form.Label>¿Qué producto buscas?</Form.Label>
                <Form.Control as="select">
                  <option>Seleccionar uno...</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Información adicional de su cotización</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
              <button className="form-send">Enviar</button>
            </Form>
          </div>
        </Container>
      </section> */}
    </div>
  );
};
export default Home;
