import firebase from "firebase";

var firebaseConfig = {
  // Firebase credentials
  apiKey: "AIzaSyBH7ohpCSQib0g1_8aF_1qvRFwpzEkBItc",
  authDomain: "prominox-usa.firebaseapp.com",
  projectId: "prominox-usa",
  storageBucket: "prominox-usa.appspot.com",
  messagingSenderId: "1051833426928",
  appId: "1:1051833426928:web:8017d0a550efc62c577335",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export default db;
