import React from "react";

// Helmet
import Helmet from "react-helmet";

// Bootstrap
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

// Custom styles
import "./legal.styles.scss";

export default function ReturnPolicy() {
  return (
    <>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Políticas de devolución - Prominox</title>
        <meta
          name="description"
          content="30+ años de experiencia. Líderes en la comercialización de acero inoxidable, aluminio, tubería conduit y accesorios para barandales."
        />
        <meta
          name="keywords"
          content="Herrajes, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Políticas de devolución - Prominox" />
        <meta
          itemprop="description"
          content="30+ años de experiencia. Líderes en la comercialización de acero inoxidable, aluminio, tubería conduit y accesorios para barandales."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Políticas de devolución - Prominox"
        />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Políticas de devolución - Prominox"
        />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero--legal">
        <Jumbotron fluid className="jumbotron--legal"></Jumbotron>
      </section>
      <div className="legal__info">
        <Container>
          <h1 className="sm text-center">
            POLÍTICA DE RECLAMACIÓN Y DEVOLUCIÓN DE CLIENTE
          </h1>
          <p>
            Estimado Cliente, agradeciendo de su compra, Prominox le comparte su
            Política de Reclamación y Devolución:
          </p>
          <ol>
            <li>
              Toda reclamación o devolución deberá ser notificada al
              Departamento de Ventas, mismo que se encargará de canalizarla al
              Departamento de Calidad.
            </li>
            <li>
              El Departamento de Calidad de Prominox validará que el producto
              esté conforme y apto para su reingreso al almacén de Prominox
              antes de aceptar cualquier devolución. Prominox establece los
              siguientes tiempos estándar para manejo de cualquier reclamación
              recibida:
              <ul>
                <li>
                  Elaboración de la queja: 24 horas hábiles máximo desde la
                  recepción.
                </li>
                <li>
                  Respuesta preliminar a la queja: 72 horas hábiles desde la
                  elaboración, a excepción de cuando se necesite inspeccionar
                  físicamente el material reclamado.
                </li>
                <li>
                  Queda en la responsabilidad del cliente notificar cualquier
                  anomalía sobre los materiales recibidos:
                  <ul>
                    <li>
                      De inmediato, antes de la descarga, en caso de material
                      que presenta evidentes daños de maniobra
                    </li>
                    <li>
                      Dentro de los 30 días desde la recepción para defectos
                      estéticos
                    </li>
                    <li>
                      Dentro de los 60 días desde la recepción para defectos no
                      visibles.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Toda devolución aceptada imputable a Prominox deberá ser recogida
              en un plazo no mayor a 5 días hábiles o de acuerdo con lo
              negociado con el Ejecutivo de Ventas.
            </li>
            <li>
              El producto de devolución no deberá presentar una condición ajena
              a la reclamada y aceptada por el Departamento de Calidad.
            </li>
            <li>
              No se acepta reclamación o devolución de material por:
              <ul>
                <li>Producto procesado o producto en partes.</li>
                <li>
                  Comisión o cambios no notificados a alguna especificación o
                  requisito especial no declarado en la Orden de Compra del
                  cliente
                </li>
                <li>
                  Cara inferior en acabado, tono y rugosidad cuando no haya sido
                  especificado por el cliente desde la etapa de cotización y se
                  haya contemplado el costo adicional de un acabado especifico.
                </li>
                <li>
                  Producto o material vendido con clasificación de venta de
                  segunda calidad o chatarra.
                </li>
                <li>
                  Diferencias en peso imputables a diferencia entre basculas
                  debidamente calibradas ante un organismo certificado.
                </li>
              </ul>
            </li>
            <li>
              Para venta de material en bobina o fleje Prominox garantiza la
              calidad del 97% del material.
            </li>
            <li>
              De aceptar una devolución cuyo producto se encuentre en las mismas
              condiciones de la venta inicial y cuyas razones de devolución no
              dependan de Prominox se podrá hacer un cargo adicional por gastos
              y trámites administrativos.
            </li>
            <li>
              Los gastos de maniobras, almacenamiento, inspecciones, sorteos de
              producto y transportación deberán ser absorbidos por el cliente
              cuando no haya una responsabilidad de origen hacia Prominox por
              concepto de producto no conforme o fuera de especificaciones a lo
              solicitado.
            </li>
            <li>
              No aplican cambios físicos derivados de una devolución; si el
              cliente requiere un producto diferente a lo entregado se cancelará
              la factura a través de nota de crédito y se generará una nueva por
              el producto solicitado.
            </li>
            <li>
              Toda Nota de Crédito será emitida al haber confirmado que el
              material devuelto coincida con lo pactado y en un lapso no mayor a
              los 2 días hábiles desde la recepción del material.
            </li>
            <li>
              Previa negociación, el material podría permanecer en las
              instalaciones del cliente y ser dispuesto a conveniencia. En este
              caso Prominox emitirá una nota de crédito por la diferencia del
              valor comercial del material contra el precio de mercado de la
              chatarra.
            </li>
          </ol>
          <h3>¡Gracias por su preferencia!</h3>
        </Container>
      </div>
    </>
  );
}
