import React, { useState } from "react";
// import Query from "../Query";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logoUrl from "../../assets/logo-usa-color.png";
import prominoxLogoWhiteUrl from "../../assets/prominox-logo--white.png";
import { BlueButton } from "../Buttons/Buttons";

// import CATEGORIES_QUERY from "../../queries/category/categories";
import "./nav.styles.scss";

/* <nav className="uk-navbar-container" data-uk-navbar>
                <div className="uk-navbar-left">
                  <ul className="uk-navbar-nav">
                    <li>
                      <Link to="/">Strapi Blog</Link>
                    </li>
                  </ul>
                </div>
                <div className="uk-navbar-right">
                  <ul className="uk-navbar-nav">
                    {categories.map((category, i) => {
                      return (
                        <li key={category.id}>
                          <Link
                            to={`/category/${category.id}`}
                            className="uk-link-reset"
                          >
                            {category.name}
                          </Link>
                        </li>
                      );
                    })}
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                  </ul>
                </div>
              </nav> */

/* <Query query={CATEGORIES_QUERY} id={null}>
        {({ data: { categories } }) => {
          return (
            
          );
        }}
      </Query> */

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  // useEffect(() => {

  // }, []);
  // if (expanded) {
  //   setTimeout(function () {
  //     setExpanded(false);
  //   }, 3000);
  // }
  return (
    <Navbar expand="lg" expanded={expanded}>
      <Navbar.Brand>
        <Link to="/" className="d-block d-lg-none">
          <img src={logoUrl} alt="Prominox Aceros" />
        </Link>
        <Link to="/" className="d-none d-lg-block">
          <img src={prominoxLogoWhiteUrl} alt="Prominox Aceros" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />
      <Navbar.Collapse id="basic-navbar-nav justify-content-center">
        <Nav className="ml-auto">
          <Link to="/" onClick={() => setExpanded(false)}>
            home
          </Link>
          <Link to="/value-added-services/" onClick={() => setExpanded(false)}>
            value added services
          </Link>
          <NavDropdown
            title="products"
            id="basic-nav-dropdown"
            className="mt-md-2 mt-lg-0"
            style={{ color: "#fff" }}
          >
            <NavDropdown.Item
              as={Link}
              to="/aluminum"
              onClick={() => setExpanded(false)}
            >
              Aluminum
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/stainless-steel/"
              onClick={() => setExpanded(false)}
            >
              {/* <Link
                to="/acero-inoxidable/"
                // className="dropdown-item"
                onClick={() => setExpanded(false)}
              > */}
              Stainless Steel
              {/* </Link> */}
            </NavDropdown.Item>
            {/* <NavDropdown.Item
              as={Link}
              to="/aluminio/"
              onClick={() => setExpanded(false)}
            >

              Aluminio
            </NavDropdown.Item> */}
            <NavDropdown.Item
              as={Link}
              to="/conduit-pipeline"
              onClick={() => setExpanded(false)}
            >
              {/* <Link
                to="/tuberia/"
                // className="dropdown-item"
                onClick={() => setExpanded(false)}
              > */}
              Conduit Pipe
              {/* </Link> */}
            </NavDropdown.Item>
            {/* <NavDropdown.Item
              as={Link}
              to="/accesorios-barandales/"
              onClick={() => setExpanded(false)}
            >
              Accesorios para barandales
            </NavDropdown.Item> */}
          </NavDropdown>
          <NavDropdown
            title="contact us"
            id="basic-nav-dropdown"
            className="mt-md-2 mt-lg-0"
            style={{ color: "#fff" }}
          >
            <NavDropdown.Item
              as={Link}
              to="/contact-us-dallas"
              onClick={() => setExpanded(false)}
            >
              Dallas
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/contact-us-houston"
              onClick={() => setExpanded(false)}
            >
              Houston
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Link to="/blog" onClick={() => setExpanded(false)}>
            blog
          </Link> */}
          {/* <Link to="/blog">Blog</Link> */}
          <BlueButton text="request to quote" target="#contact" />
          {/* {categories.map((category, i) => {
                      return (
                        <li key={category.id}>
                          <Link
                            to={`/category/${category.id}`}
                            className="uk-link-reset"
                          >
                            {category.name}
                          </Link>
                        </li>
                      );
                    })} */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
