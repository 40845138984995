import React from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom components
import Contact from "../Contact/Contact";

// Custom styles
import "./contact-us.styles.scss";

// Media assets
import locationIcon from "../../assets/icn-loc.png";
import phoneIcon from "../../assets/icn-tel.png";
import faxIcon from "../../assets/icn-fax.png";
import mailIcon from "../../assets/icn-mail.png";

export default function ContactDallas() {
  return (
    <>
      <section className="contact-us-jumbotron">
        <Container>
          <Row>
            <Col xs="12" lg={{ span: 6, offset: 6 }}>
              <h2>Contact Us</h2>
              <ul>
                <li>
                  <a
                    href="https://goo.gl/maps/VW8E1JaqkJfAETAa9"
                    target="_blank"
                  >
                    <img src={locationIcon} alt="Prominox USA" /> 2805 Market
                    Street, Suite 150, Garland, Texas 75041
                  </a>
                </li>
                <li>
                  <a tel="7134645040">
                    <img src={phoneIcon} alt="Prominox USA" /> 972/ 864-4615
                  </a>
                </li>
                <li>
                  <a tel="7134645040">
                    <img className="second" src={faxIcon} alt="Prominox USA" />{" "}
                    972/ 864-4616
                  </a>
                </li>
                <li>
                  <a mailto="sales@prominox-usa.com">
                    <img className="second" src={mailIcon} alt="Prominox USA" />{" "}
                    sales@prominox-usa.com
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1675.285618827786!2d-96.669575!3d32.883062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864ea0b6a2003d13%3A0x36e7d910b866d92e!2s2805%20Market%20St%2C%20Garland%2C%20TX%2075041%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1649827238337!5m2!1ses-419!2smx"
          width="100%"
          style={{ border: "0", height: "100vh" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
      <Contact />
    </>
  );
}
