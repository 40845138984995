import React, { useEffect } from "react";

import { Switch, Route } from "react-router-dom";

import Helmet from "react-helmet";

import NavBar from "../../components/Nav";
import Article from "../Article";
import Categories from "../Categories";
import Category from "../Category";
import Home from "../../components/Home/Home";
import Footer from "../../components/Footer/Footer";
// import Accesories from "../../components/Accesories/Accesories";
import Steel from "../../components/Steel/Steel";
import ValueAddedServices from "../../components/ValueAdded/ValueAddedServices";
import Pipeline from "../../components/Pipeline/Pipeline";
import Aluminum from "../../components/Aluminum/Aluminum";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Video from "../../components/Video/Video";
// import Herrajes from "../Landings/Herrajes";
// import Tuberia from "../Landings/Tuberia";
// import Aluminio from "../Landings/Aluminio";
// import AceroInoxidable from "../Landings/AceroInoxidable";
// import Submission from "../Landings/Submission";
import PrivacyNotice from "../Legal/PrivacyNotice";
import ReturnPolicy from "../Legal/ReturnPolicy";
import ContactDallas from "../../components/ContactUs/ContactDallas";
import ContactHouston from "../../components/ContactUs/ContactHouston";
// import Aluminum from "../../components/Aluminum/Aluminum";

// const tawkToPropertyId = '609188e3b1d5182476b5938c';
// const tawkToKey = 'e09c937c053e371796a1effe2c67f20dc9f4befe';

function App() {
  // useEffect(() => {
  //   document.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //   });
  // }, []);
  return (
    <div className="App prevent-select">
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <html lang="es-MX" />
        <title>Inicio | Prominox</title>
        <meta
          name="description"
          content="Empresa mexicana dedicada a la comercialización de acero inoxidable, aluminio, tubería conduit y herrajes, con 20 sucursales en México."
        />
        <meta
          name="keywords"
          content="acero inoxidable, aluminio, tubería conduit, herrajes, 20 sucursales, empresa mexicana, líder en la industria"
        />
        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Inicio | Prominox" />
        <meta
          itemprop="description"
          content="Empresa mexicana dedicada a la comercialización de acero inoxidable, aluminio, tubería conduit y herrajes, con 20 sucursales en México."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Inicio | Prominox" />
        <meta
          property="og:description"
          content="Empresa mexicana dedicada a la comercialización de acero inoxidable, aluminio, tubería conduit y herrajes, con 20 sucursales en México."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Inicio | Prominox" />
        <meta
          name="twitter:description"
          content="Empresa mexicana dedicada a la comercialización de acero inoxidable, aluminio, tubería conduit y herrajes, con 20 sucursales en México."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <NavBar />
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Home} exact />
        {/* <Route path="/accesorios-barandales" component={Accesories} exact /> */}
        {/* <Route path="/aceroinoxidableads" component={AceroInoxidable} exact /> */}
        {/* <Route path="/aluminio" component={Aluminum} exact /> */}
        {/* <Route path="/aluminioads" component={Aluminio} exact /> */}
        <Route path="/aviso-de-privacidad" component={PrivacyNotice} exact />
        <Route path="/aluminum" component={Aluminum} exact />
        <Route path="/blog" component={Categories} exact />
        <Route path="/blog/articulo/:id" component={Article} exact />
        <Route path="/blog/categoria/:id" component={Category} exact />
        <Route path="/contact-us-dallas" component={ContactDallas} exact />
        <Route path="/contact-us-houston" component={ContactHouston} exact />
        {/* <Route path="/centro-de-servicio" component={CentroServicio} exact /> */}
        <Route path="/conduit-pipeline" component={Pipeline} exact />
        {/* <Route path="/gracias-:id" component={Submission} exact />
        <Route path="/herrajesparabarandalesads" component={Herrajes} exact /> */}
        <Route path="/politicas-de-devolucion" component={ReturnPolicy} exact />
        <Route path="/stainless-steel" component={Steel} exact />
        {/* <Route path="/tuberiaconduitads" component={Tuberia} exact /> */}
        <Route path="/video" component={Video} exact />
        <Route
          path="/value-added-services"
          component={ValueAddedServices}
          exact
        />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
