import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import { Helmet } from "react-helmet";

import "../Jumbotron/jumbotron.styles.scss";
import "./value-added-services.styles.scss";
import Contact from "../Contact/Contact";

// import customIconUrl from "../../assets/custom-icon--white.svg";
// import qualityIconUrl from "../../assets/quality-icon--white.svg";
// import polishingIconUrl from "../../assets/lamina-300x151.png";
// import requirementIconUrl from "../../assets/requirements-icon--white.svg";
// import trustIconUrl from "../../assets/trust-icon--white.svg";
// import oneStopIconUrl from "../../assets/icn-home9.png";
// import deliverytIconUrl from "../../assets/delivery-icon--white.svg";
// import inventoryIconUrl from "../../assets/inventory-icon--white.svg";

import service1IconUrl from "../../assets/icn-service-01.png";
import service3IconUrl from "../../assets/icn-service-03.png";
import service11IconUrl from "../../assets/icn-service-11.png";
import service5IconUrl from "../../assets/icn-service-05.png";
import service6IconUrl from "../../assets/icn-service-06.png";
import service8IconUrl from "../../assets/icn-service-08.png";
import service9IconUrl from "../../assets/icn-service-09.png";
import service10IconUrl from "../../assets/icn-service-10.png";
import service12IconUrl from "../../assets/icn-service-12.png";
import service13IconUrl from "../../assets/icn-service-13.png";
import service14IconUrl from "../../assets/icn-rolling.png";
import service15IconUrl from "../../assets/icn-polishing.png";
import service16IconUrl from "../../assets/icn-metallurgical.png";
import service17IconUrl from "../../assets/icn-quality-1.png";
import service18IconUrl from "../../assets/icn-quality-2.png";
import service19IconUrl from "../../assets/icn-quality-3.png";

// import ServiceCenterVideo from "../../assets/videos/CENTRO-DE-SERVICIO.mp4";

export default function CentroServicio() {
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Centro de Servicio</title>
        <meta
          name="description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta
          name="keywords"
          content="acero inoxidable, aluminio, tubería conduit, herrajes, centro de servicio, san luis potosi"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Centro de Servicio" />
        <meta
          itemprop="description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Centro de Servicio" />
        <meta
          property="og:description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Centro de Servicio" />
        <meta
          name="twitter:description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="why-us">
        <Container fluid>
          <h5 className="wow fadeInDown">
            WE OFFER A MENU OF SERVICES TO MAKE YOUR JOB EASIER
          </h5>
          <Row className="wow fadeInUp" data-wow-delay=".7s">
            <Col xs={12} lg={6}>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={service1IconUrl} alt="Producto a la medida" />
                </div>
                <p>The convenience and precision of cut-to-length sheets</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={service3IconUrl} alt="Calidad del producto" />
                </div>
                <p>Shear to size (1/2” x 20’ capability)</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={service11IconUrl} alt="Atención personalizada" />
                </div>
                <p>Just-in-time stock and release program</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={service5IconUrl} alt="Requerimientos especiales" />
                </div>
                <p>Same day will call and next day delivery</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={service6IconUrl} alt="Requerimientos especiales" />
                </div>
                <p>Same day invoicing</p>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="why-us__individual">
                <div>
                  <img src={service8IconUrl} alt="Entrega a tiempo" />
                </div>
                <p>Hi-def Plasma cutting (in-house)</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={service9IconUrl} alt="Proveedor confiable" />
                </div>
                <p>Manufactured flat bar [in-house]</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={service10IconUrl} alt="Inventario de producto" />
                </div>
                <p>An array of protective PVC coatings</p>
              </div>
              <div className="why-us__individual mt-0 mt-lg-4">
                <div>
                  <img
                    src={service12IconUrl}
                    alt="Cobertura de distribución nacional"
                  />
                </div>
                <p>Mill Test reports</p>
              </div>
              <div className="why-us__individual mt-0 mt-lg-4">
                <div>
                  <img
                    src={service13IconUrl}
                    alt="Cobertura de distribución nacional"
                    style={{ height: "auto" }}
                  />
                </div>
                <p>Our own fleet of delivery trucks</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="other-services">
        <Container>
          <h5 className="wow fadeInUp">other services</h5>
          <Row>
            <Col xs={6} className="wow fadeInUp" data-wow-delay=".3s">
              <div>
                <img src={service8IconUrl} alt="Entrega a tiempo" />
              </div>
              <p>Laser and Water Jet cutting</p>
            </Col>
            <Col xs={6} className="wow fadeInUp" data-wow-delay=".5s">
              <div>
                <img src={service14IconUrl} alt="Entrega a tiempo" />
              </div>
              <p>Rolling and Welding</p>
            </Col>
            <Col xs={6} className="last wow fadeInUp" data-wow-delay=".8s">
              <div>
                <img src={service15IconUrl} alt="Entrega a tiempo" />
              </div>
              <p>Polishing</p>
            </Col>
            <Col xs={6} className="last wow fadeInUp" data-wow-delay="1s">
              <div>
                <img src={service16IconUrl} alt="Entrega a tiempo" />
              </div>
              <p>Technical metallurgical resources</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="quality-assurance">
        <Container>
          <h5 className="wow fadeInUp">quality assurance</h5>
          <p className="wow fadeInUp" data-wow-delay=".3s">
            For 25 years we’ve been providing the best metal at the best price
            for our customers.
          </p>
          <p className="wow fadeInUp" data-wow-delay=".5s">
            All of our products are carefully inspected for consistent quality
            before being put into inventory and meets all global standards.
          </p>
          <p className="wow fadeInUp" data-wow-delay=".8s">
            We provide you with all the standard industry certiﬁcations and can
            also offer:
          </p>
          <Row className="wow fadeInUp" data-wow-delay="1.2s">
            <Col xs={12} lg={4}>
              <img src={service17IconUrl} alt="Prominox USA" />
              <p>Full mill certiﬁcation and traceability</p>
            </Col>
            <Col xs={12} lg={4}>
              <img src={service18IconUrl} alt="Prominox USA" />
              <p>Available Micrometer Readings</p>
            </Col>
            <Col xs={12} lg={4}>
              <img src={service19IconUrl} alt="Prominox USA" />
              <p>Full mill certiﬁcation and traceability</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="centro-de-servicio">
        <Container>
          <h2 className="wow fadeInDown">nuestras líneas de producción</h2>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} className="wow fadeInUp" data-wow-delay=".4s">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Corte</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Nivelado</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Pulido</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} className="wow fadeInUp" data-wow-delay=".6s">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div>
                      <ul>
                        <li>
                          <span>ESPESOR:</span>
                          <p>Mínimo: 0.61 mm. / CALIBRE 24</p>
                          <p>Máximo: 6.35 mm. ¼ PULGADA</p>
                        </li>
                        <li>
                          <span>ANCHO:</span>
                          <p>Mínimo: 609.6 mm. (2 PIES) </p>
                          <p>Máximo: 1,828.8 mm. (6 PIES)</p>
                        </li>
                        <li>
                          <span>LARGO:</span>
                          <p>Mínimo: 609.66 mm. (2 PIES) </p>
                          <p>Máximo: 12,192 mm. (40 PIES)</p>
                        </li>
                        <li>
                          <span>PESO:</span>
                          <p>Mínimo: 500 kg.</p>
                          <p>Máximo: 3,000 kg.</p>
                        </li>
                      </ul>
                      <video controls muted className="video">
                        <source src={CutVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div>
                      <ul>
                        <li>
                          <span>ESPESOR:</span>
                          <p>Mínimo: 0.61 mm. / CALIBRE 24</p>
                          <p>Máximo 6.35MM ¼ PULGADA</p>
                        </li>
                        <li>
                          <span>ANCHO:</span>
                          <p>Mínimo: 609.6 mm. (2 PIES) </p>
                          <p>Máximo: 1,828.8 MM (6 PIES)</p>
                        </li>
                        <li>
                          <span>LARGO:</span>
                          <p>Mínimo 609.66 mm. (2 PIES)</p>
                          <p>Máximo: 12,192 mm. (40 PIES)</p>
                        </li>
                        <li>
                          <span>PESO:</span>
                          <p>Mínimo: 500 KG</p>
                          <p>Máximo: 3,000 KG</p>
                        </li>
                      </ul>
                      <video controls muted className="video">
                        <source src={LeveledVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div>
                      <ul className="third">
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Scotch brite 1 Seco Fine
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Scotch brite 2 Seco
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Scotch brite 3 Seco
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P4 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P5 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Hairline Normal
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Hairline Mirror
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">BA</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Mirror P8 Normal
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P2 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P3 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P3 húmedo</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P4 húmedo</p>
                        </li>
                      </ul>
                      <video controls muted className="video">
                        <source src={PolishVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section> */}
      <Contact />
    </div>
  );
}
