import React from "react";

// Helmet
import Helmet from "react-helmet";

// Bootstrap
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

// Custom styles
import "./legal.styles.scss";

export default function PrivacyNotice() {
  return (
    <>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Aviso de privacidad - Prominox</title>
        <meta
          name="description"
          content="30+ años de experiencia. Líderes en la comercialización de acero inoxidable, aluminio, tubería conduit y accesorios para barandales."
        />
        <meta
          name="keywords"
          content="Herrajes, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Aviso de privacidad - Prominox" />
        <meta
          itemprop="description"
          content="30+ años de experiencia. Líderes en la comercialización de acero inoxidable, aluminio, tubería conduit y accesorios para barandales."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Aviso de privacidad - Prominox" />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Aviso de privacidad - Prominox" />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero--legal">
        <Jumbotron fluid className="jumbotron--legal"></Jumbotron>
      </section>
      <section className="legal__info">
        <Container>
          <h1 className="text-center">aviso de privacidad</h1>
          <p>
            A todos nuestros empleados, clientes, proveedores, amigos y
            visitantes:
          </p>
          <p>
            Promotora Industrial GIM, S.A. de C.V. (en adelante mencionada
            indistintamente como: La Empresa, PROGIM o PROMINOX) hace de su
            conocimiento que todos sus datos personales, incluso los sensibles y
            los patrimoniales o financieros, recabados con motivo de la relación
            comercial, administrativa, laboral o jurídica que tengamos, o que,
            en su caso, llegásemos a tener, se tratarán única y exclusivamente
            para todos los fines establecidos en dicha relación. En estos fines
            se incluyen sin limitación a otros análogos: la identificación,
            operación, administración, análisis, ofrecimiento y promoción de
            bienes, productos y servicios y/o prospección comercial, así como
            para cumplir las obligaciones derivadas de nuestra relación y otros
            fines compatibles, quedando convenido que usted acepta la
            tácitamente la transferencia que pudiera realizarse.
          </p>
          <p>
            Para garantizar y limitar el uso y divulgación de sus datos,
            mantenemos políticas de seguridad, aplicamos y respetamos sus
            derechos de ARCO al mantener la confidencialidad de dicha
            información desde la contratación de nuestro personal, con
            proveedores, clientes amigos y visitantes de PROMINOX.
          </p>
          <p>
            El ejercicio de sus derechos de Acceso, Rectificación, Cancelación,
            Oposición (ARCO) y en su caso, la revocación del consentimiento
            otorgado deberá realizarse por escrito o a través de correo
            electrónico, que deberá de ser dirigido a nuestro representante al
            correo electrónico rafael.latapi@grupogim.mx que está habilitado
            única y exclusivamente para este uso.
          </p>
          <p>
            Así mismo, al ingresar a cualquiera de nuestras instalaciones usted
            y sus acompañantes deberán registrarse para acceder y pueden por
            seguridad ser videograbados aplicándose en todo momento sus derechos
            de ARCO a esta o cualquier otro dato personal que obtengamos para
            control.
          </p>
          <p>
            El presente aviso, así como sus modificaciones, estará a su
            disposición en cada uno de nuestros puntos de venta y en nuestra
            página web en la dirección www.prominox.com.mx.
          </p>
          <p>
            Queda señalado en el presente aviso que el responsable de la
            información y encargado de la administración de la misma para
            efectos de la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares se ubica en la siguiente dirección de
            correo electrónico rafael.latapi@grupogim.mx dirección a la cual se
            deberá dirigir cualquier aclaración o notificación respecto del
            presente.
          </p>
          <p>Atentamente</p>
          <h3>PROMOTORA INDUSTRIAL GIM S.A. DE C.V.</h3>
          <p>
            NOTA: Este aviso fue elaborado y sustentado en las disposiciones de
            la Ley Federal de Protección de Datos Personales en Posesión de
            Particulares (LFPDPPP).
          </p>
        </Container>
      </section>
    </>
  );
}
