import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import { Helmet } from "react-helmet";

import "./aluminum.styles.scss";

import aluminioIconUrl from "../../assets/img-home-aluminio.png";

// import certification1ImgUrl from "../../assets/logo-cfe.jpg";
// import certification2ImgUrl from "../../assets/logo-imss.jpg";
// import certification3ImgUrl from "../../assets/logo-ance.jpg";
// import certification4ImgUrl from "../../assets/logo-ul.jpg";
// import certification5ImgUrl from "../../assets/logo-lapem.jpg";

import firstIconUrl from "../../assets/icn-aluminio-04.svg";
import secondIconUrl from "../../assets/icn-aluminio-01.svg";
import thirdIconUrl from "../../assets/icn-02.svg";
import fourthIconUrl from "../../assets/icn-06.svg";
import fifthIconUrl from "../../assets/icn-aluminio-01.svg";
import sixthIconUrl from "../../assets/icn-aluminio-05.svg";
import seventhIconUrl from "../../assets/icn-aluminio-08.svg";
import eigthIconUrl from "../../assets/icn-07.svg";
import ninethIconUrl from "../../assets/icn-01.svg";
import tenthIconUrl from "../../assets/icn-aluminio-07.svg";
import eleventhIconUrl from "../../assets/icn-aluminio-03.svg";
import twelveIconUrl from "../../assets/icn-aluminio-02.svg";

import grade100 from "../../assets/20.png";
import grade300 from "../../assets/21.png";
import grade500 from "../../assets/22.png";
import grade600 from "../../assets/23.png";
// import garde100 from "../../assets/icn-aluminio-02.svg";
// import garde100 from "../../assets/icn-aluminio-02.svg";

import aluminioLaminaImgUrl from "../../assets/aluminum-coil.png";
import aluminioBlankImgUrl from "../../assets/aluminum-coil-white-wash.png";
import aluminioPlacaImgUrl from "../../assets/aluminum-sheet.png";
import aluminioFlejeImgUrl from "../../assets/aluminum-slit-coil.png";
import aluminioAntiImgUrl from "../../assets/aluminum-plate.png";
import aluminioRoBlancoImgUrl from "../../assets/aluminum-tread-plate.png";
import arrowDownIconUrl from "../../assets/arrow-down.svg";

import AluminumVideo from "../../assets/videos/ALUMINIO.mp4";

import Contact from "../Contact/Contact";

export default function Aluminum() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Aluminum</title>
        <meta
          name="description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta
          name="keywords"
          content="aluminio, venta de aluminio, placa antiderrapante, rollo liso natural, rollo blanco wash, placa liso natural, blank, serie 1050, serie 1100, serie 3003, serie 5052, serie 5454, serie 3001"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Aluminio" />
        <meta
          itemprop="description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Aluminio" />
        <meta
          property="og:description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Aluminio" />
        <meta
          name="twitter:description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-aluminum mb-0">
          <Container
          // style={{
          //   position: "absolute",
          //   width: "100%",
          //   top: "0",
          //   height: "100%",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          >
            <h1 className="wow fadeInUp">aluminum</h1>
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".5s"
            >
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aluminioIconUrl} alt="Non-magnetic" />
                  <p>Non-magnetic</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img
                    src={aluminioIconUrl}
                    alt="High resistance conductivity"
                  />
                  <p>High resistance conductivity</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img
                    src={aluminioIconUrl}
                    alt="High electrical conductivity"
                  />
                  <p>High electrical conductivity</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aluminioIconUrl} alt="High mechanical properties" />
                  <p>High mechanical properties</p>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      {/* <section className="carousel--aluminum-container mt-n5">
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--aluminum-item carousel--aluminum-item--1">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">1000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <div className="d-flex wow fadeInUp" data-wow-delay=".3s">
                  <h4>1050</h4>
                  <h4>1100</h4>
                  <h4>1350</h4>
                </div>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={fifthIconUrl}
                        alt="PROMINOX High electrical conductivity"
                      />
                      <p>High electrical conductivity</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={ninethIconUrl}
                        alt="PROMINOX High thermal conductivity"
                      />
                      <p>High thermal conductivity</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>High resistance to the corrosion</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--2">
            <div className="carousel--aluminum-item carousel--aluminum-item--2">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">2000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  2024
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={seventhIconUrl}
                        alt="PROMINOX Good mechanical properties"
                      />
                      <p>Good mechanical properties</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={eigthIconUrl}
                        alt="PROMINOX High resistance to the corrosion"
                      />
                      <p>High resistance to the corrosion</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={tenthIconUrl}
                        alt="PROMINOX Sandwich presentation"
                      />
                      <p>Sandwich presentation</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--3">
            <div className="carousel--aluminum-item carousel--aluminum-item--3">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">3000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <div className="d-flex wow fadeInUp" data-wow-delay=".3s">
                  <h4>3003</h4>
                  <h4>3004</h4>
                  <h4>3001</h4>
                  <h4>3105</h4>
                </div>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={seventhIconUrl}
                        alt="PROMINOX Good mechanical properties"
                      />
                      <p>Good mechanical properties</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={eigthIconUrl}
                        alt="PROMINOX High resistance to the corrosion"
                      />
                      <p>High resistance to the corrosion</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--4">
            <div className="carousel--aluminum-item carousel--aluminum-item--4">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">4000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  4030
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={sixthIconUrl} alt="PROMINOX" />
                      <p>Good mechanical properties</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--5">
            <div className="carousel--aluminum-item carousel--aluminum-item--5">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">5000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <div className="d-flex wow fadeInUp" data-wow-delay=".3s">
                  <h4>5005</h4>
                  <h4>5052</h4>
                  <h4>5454</h4>
                  <h4>5754</h4>
                </div>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={seventhIconUrl}
                        alt="PROMINOX Good mechanical properties"
                      />
                      <p>Good mechanical properties</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={fourthIconUrl}
                        alt="PROMINOX Excellent weldability"
                      />
                      <p>Excellent weldability</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={eigthIconUrl}
                        alt="PROMINOX High resistance to marine corrosion"
                      />
                      <p>High resistance to marine corrosion</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--6">
            <div className="carousel--aluminum-item carousel--aluminum-item--6">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">6000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  6061
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={secondIconUrl}
                        alt="PROMINOX Medium resistance to loads"
                      />
                      <p>Medium resistance to loads</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={thirdIconUrl} alt="PROMINOX Good formability" />
                      <p>Good formability</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--7">
            <div className="carousel--aluminum-item carousel--aluminum-item--7">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">7000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  7075
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={seventhIconUrl}
                        alt="PROMINOX High mechanical resistance"
                      />
                      <p>High mechanical resistance</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--8">
            <div className="carousel--aluminum-item carousel--aluminum-item--8">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">8000 series</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  common alloys
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  8081
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>properties</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={firstIconUrl}
                        alt="PROMINOX ase of lamination"
                      />
                      <p>Ease of lamination</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img
                        src={eleventhIconUrl}
                        alt="PROMINOX Tensile strength"
                      />
                      <p>Tensile strength</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={twelveIconUrl} alt="PROMINOX Good plasticity" />
                      <p>Good plasticity</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section> */}
      {/* <section className="pipeline-characteristics">
        <Jumbotron fluid className="jumbotron--hero-pipeline__characteristics">
          <Container>
            <h1>características</h1>
            <div className="jumbotron--hero__cards__container">
              <Row className="justify-content-md-center">
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={seventhIconUrl} alt="PROMINOX" />
                    <p>Acero de primera calidad</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={thirdIconUrl} alt="PROMINOX" />
                    <p>Rolado en frío</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={secondIconUrl} alt="PROMINOX" />
                    <p>Pintura anticorrosiva en el interior</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={firstIconUrl} alt="PROMINOX" />
                    <p>Cuerdas libres de rebaba</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={fourthIconUrl} alt="PROMINOX" />
                    <p>Galvanizado exterior</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={sixthIconUrl} alt="PROMINOX" />
                    <p>Soldadura de alta frecuencia</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section> */}
      <section className="steel-lineup">
        <Container>
          <h3 className="wow fadeInUp">our line products</h3>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={4} className="mb-0 mb-md-0">
              <img src={aluminioLaminaImgUrl} alt="Aluminum" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5>COIL</h5>
            </Col>
            <Col xs={6} md={4} className="mb-0 mb-md-0 text-left">
              <img src={aluminioBlankImgUrl} alt="Aluminum" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5>COIL WHITE WASH</h5>
            </Col>
            <Col xs={6} md={4} className="mb-0 mb-md-0">
              <img src={aluminioPlacaImgUrl} alt="Aluminum" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5>SHEET</h5>
            </Col>
            <Col xs={6} md={4} className="mb-0 mb-md-0">
              <img src={aluminioFlejeImgUrl} alt="Aluminum" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5>SLIT COIL</h5>
            </Col>
            <Col xs={6} md={4} className="mb-0 mb-md-0">
              <img src={aluminioAntiImgUrl} alt="Aluminum" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5>PLATE</h5>
            </Col>
            <Col xs={6} md={4} className="mb-0 mb-md-0">
              <img src={aluminioRoBlancoImgUrl} alt="Aluminum" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5>TREAD PLATE</h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="aluminum-grades">
        <div className="grade grade-1000"></div>
        <div className="grade grade-3000"></div>
        <div className="grade grade-5000"></div>
        <div className="grade grade-6000"></div>
        {/* <img src={grade500} alt="" />
        <img src={grade100} alt="" /> */}
      </section>
      <div className="grade grade-8000"></div>
      <div className="carousel--aluminum__applications-container">
        <div className="carousel-caption--top">
          <h4 className="wow fadeInUp">APPLICATIONS</h4>
        </div>
        <Carousel interval={null} controls={false}>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--1">
              {/* <h2 className="wow fadeInUp" data-wow-delay=".3s">
                Industrial kitchens
              </h2> */}
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                {/* <div>
                  <h4>aluminum + copper. common alloys: 2024</h4>
                </div> */}
                <div>
                  {/* <h4>properties</h4> */}
                  <ul>
                    <li>enclosures</li>
                    <li>heating & ventilation</li>
                    <li>oil & gas company</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <h4>properties</h4>
                  <ul>
                    <li>high mechanical resistance</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--2">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                Welds
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminum + silicon</h4>
                  <h4>common alloys: 4030</h4>
                </div>
                <div>
                  <h4>properties</h4>
                  <ul>
                    <li>low melting point</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--3">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                ships
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminum + manganese + magnesium</h4>
                  <h4>common alloys: 5005, 5052, 5454, 5754</h4>
                </div>
                <div>
                  <h4>properties</h4>
                  <ul>
                    <li>high mechanical properties</li>
                    <li>high weldability</li>
                    <li>high resistance to marine corrosion</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--4">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                <span>aircraft</span> structures
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminum + zinc</h4>
                  <h4>common alloys: 7075</h4>
                </div>
                <div>
                  <h4>properties</h4>
                  <ul>
                    <li>high mechanical resistance</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--5">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                air-conditioning
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminum + iron</h4>
                  <h4>common alloys: 8081</h4>
                </div>
                <div>
                  <h4>properties</h4>
                  <ul>
                    <li>ease of lamination</li>
                    <li>tensile strength</li>
                    <li>good plasticity</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
      <Contact />
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
