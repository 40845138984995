import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Helmet } from "react-helmet";

import "./steel.styles.scss";

import aceroIconUrl from "../../assets/img-home-acero.png";

// import firstIconUrl from "../../assets/icn-01.svg";
// import firstIconUrl2 from "../../assets/icn-01.png";
// import secondIconUrl from "../../assets/icn-02.svg";
// import secondIconUrl2 from "../../assets/icn-02.png";
// import thirdIconUrl from "../../assets/icn-03.png";
// import fourthIconUrl from "../../assets/icn-04.png";
// import fifthIconUrl from "../../assets/icn-05.png";
// import sixthIconUrl from "../../assets/icn-06.svg";
// import sixthIconUrl2 from "../../assets/icn-06.png";
// import seventhIconUrl from "../../assets/icn-07.svg";
// import seventhIconUrl2 from "../../assets/icn-07.png";
// import tuberiaPipeImgUrl from "../../assets/acero-inoxidable-tuberia-pipe.jpg";
// import soleraImgUrl from "../../assets/acero-inoxidable-solera.jpg";
// import rolloImgUrl from "../../assets/acero-inoxidable-rollo.jpg";
// import placaImgUrl from "../../assets/acero-inoxidable-placa.jpg";
// import ornamentalRedondaImgUrl from "../../assets/acero-inoxidable-ornamental-redonda.jpg";
// import ornamentalRectangularImgUrl from "../../assets/acero-inoxidable-ornamental-rectangular.jpg";
// import ornamentalCuadradaImgUrl from "../../assets/acero-inoxidable-ornamental-cuadrada.jpg";
// import laminaImgUrl from "../../assets/acero-inoxidable-lamina.jpg";
// import flejeImgUrl from "../../assets/acero-inoxidable-fleje.jpg";
// import discoImgUrl from "../../assets/acero-inoxidable-disco.jpg";
// import blankImgUrl from "../../assets/acero-inoxidable-blank.jpg";
// import barraRedondaImgUrl from "../../assets/acero-inoxidable-barra-redonda.jpg";
// import barraCuadradaImgUrl from "../../assets/acero-inoxidable-barra-cuadrada.jpg";
// import antiderrapanteImgUrl from "../../assets/acero-inoxidable-antiderrapante.jpg";
// import anguloImgUrl from "../../assets/acero-inoxidable-angulo.jpg";
// import firstSlideImgUrl from "../../assets/accesorios-barandales-slide-1.png";
// import secondSlideImgUrl from "../../assets/accesorios-barandales-slide-2.png";
// import thirdSlideImgUrl from "../../assets/accesorios-barandales-slide-3.png";
import steelProduct1Url from "../../assets/img-stainless-round-bar.png";
import steelProduct2Url from "../../assets/img-stainless-hexagon.png";
import steelProduct3Url from "../../assets/img-stainless-square-bar.png";
import steelProduct4Url from "../../assets/img-stainless-true-flat.png";
import steelProduct5Url from "../../assets/img-stainless-steel-sheet.png";
import steelProduct6Url from "../../assets/img-stainless-steel-coil.png";
import steelProduct7Url from "../../assets/img-stainless-steel-plate.png";
import steelProduct8Url from "../../assets/img-stainless-steel-sheared.png";
import steelProduct9Url from "../../assets/img-stainless-angles.png";
import steelProduct10Url from "../../assets/img-stainless-beams.png";
import steelProduct11Url from "../../assets/img-stainless-t-bar.png";
import steelProduct12Url from "../../assets/img-stainless-ornamental.png";
import steelProduct13Url from "../../assets/img-stainless-ornamental-tubing-square.png";
import steelProduct14Url from "../../assets/img-stainless-ornamental-tubing-rectangular.png";
import arrowDownIconUrl from "../../assets/arrow-down.svg";

import Contact from "../Contact/Contact";

// import SteelVideo from "../../assets/videos/ACERO-INOX.mp4";

export default function Steel() {
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Acero inoxidable</title>
        <meta
          name="description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta
          name="keywords"
          content="acero inoxidable, rollo, placa, lámina, blank, perfiles, tubería, 304, 314, 20 sucursales"
        />
        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Acero inoxidable" />
        <meta
          itemprop="description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Acero inoxidable" />
        <meta
          property="og:description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Acero inoxidable" />
        <meta
          name="twitter:description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-steel">
          {/* <video autoPlay loop muted className="video">
            <source src={SteelVideo} type="video/mp4" />
          </video> */}
          <Container>
            <h1 className="wow fadeInUp">stainless steel</h1>
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".7s"
            >
              <Row>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Corrosion resistance</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Great hygiene and cleanliness</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Durability</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Resistance to extreme temperatures</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Excellent weldability</p>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      {/* <section className="carousel--steel-container mt-n5">
        <Carousel interval={null}>
          <Carousel.Item style={{ height: "100%" }}>
            <div className="carousel--steel-item carousel--steel-item--1">
              <div className="carousel--steel-item__heading">
                <h2 className="wow fadeInUp">ferríticos</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="wow fadeInUp" data-wow-delay=".3s">
                  <h4>409L</h4>
                  <h4>430</h4>
                  <h4>441</h4>
                </div>
              </div>
              <div
                className="carousel--steel-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={thirdIconUrl} alt="PROMINOX" />
                      <p>Magnéticos</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={secondIconUrl} alt="PROMINOX" />
                      <p>Buena formabilidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Difusión eficiente de calor</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--2">
            <div className="carousel--steel-item carousel--steel-item--2">
              <div className="carousel--steel-item__heading">
                <h2 className="wow fadeInUp">austeníticos</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="wow fadeInUp" data-wow-delay=".3s">
                  <h4>201</h4>
                  <h4>301</h4>
                  <h4>304</h4>
                  <h4>310</h4>
                  <h4>316</h4>
                </div>
              </div>
              <div
                className="carousel--steel-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={sixthIconUrl} alt="PROMINOX" />
                      <p>Excelente soldabilidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={fifthIconUrl} alt="PROMINOX" />
                      <p>Gran higiene y limpieza</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={fourthIconUrl} alt="PROMINOX" />
                      <p>Fácil dilatación y contracción</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section> */}
      <section className="steel-lineup">
        <Container fluid>
          <h3 className="wow fadeInUp">OUR PRODUCTS</h3>
          <Row className="wow fadeInUp mt-4 mt-md-0" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct1Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5 className="w-sm">Round Bar 12'R/L</h5>
              <div className="product-info">
                <ul>
                  <li>.188” thru 20” diameter</li>
                  <li>Grades: 303, 304/304L, 316/316L, 416, 410, 630.</li>
                  <li>Specs: QQS – ASTM – ASME – AMS</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct2Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>Hexagon cold drawn bars 12'R/L</h5>
              <div className="product-info">
                <ul>
                  <li>.188” thru 3”</li>
                  <li>In grades: 303, 304 / 304L, 316 / 316L, 416.</li>
                  <li>Specs: QQS – ASTM – ASME – AMS</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct3Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>SQUARE BARS COLD DRAWN 12'R/L</h5>
              <div className="product-info">
                <ul>
                  <li>.188” thru 3”</li>
                  <li>In grades: 303, 304 / 304L, 316 / 316L.</li>
                  <li>Specs: QQS – ASTM – ASME – AMS</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct4Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>TRUE FLAT BAR (HRAP) 12'R/L</h5>
              <div className="product-info">
                <ul>
                  <li>Thickness: .125” to 3”</li>
                  <li>Grades: 303, 304/304L, 316/316L, 416, 410, 630.</li>
                  <li>Specs: QQS – ASTM – ASME – AMS</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct5Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5 className="w-sm">STAINLESS STEEL SHEET</h5>
              <div className="product-info">
                <ul>
                  <li>Ingrades: 304, 304L, 316L, 430</li>
                  <li>Thickness: 7ga. thru 26 ga.</li>
                  <li>Finish: 2B, #3, #4, #8, BA add Prominox polishes</li>
                  <li>Widths: 36”, 48”, 60”, 72”</li>
                  <li>Lengths: 84”, 96”, 120”, 144”</li>
                  <li>Specs: ASTM A240, ASME SA 240</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct6Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5 className="w-sm">STAINLESS STEEL COIL</h5>
              <div className="product-info">
                <ul>
                  <li>Ingrades: 304, 304L, 316L, 430, 2205</li>
                  <li>Thickness: .500” thru 28 ga.</li>
                  <li>Finish: HRAP “1, 2B, 2D, #3, #3, BA</li>
                  <li>Widths: 36”, 48”, 60”, 72”</li>
                  <li>Specs: ASTM A240, ASME SA 240</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct7Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5 className="w-sm">STAINLESS STEEL PLATE</h5>
              <div className="product-info">
                <ul>
                  <li>
                    Ingrades: 304L, 309S, 310S, 316L, 317L, 321H, 347H, 410S{" "}
                  </li>
                  <li>Thickness: .188” thru 4”</li>
                  <li>Finish: HR, ( #3, #4, #8 pricing upon request)</li>
                  <li>Widths: 48”, 60”, 72“, 96”, 120”</li>
                  <li>Lengths: 96”, 120”, 144”, 240”</li>
                  <li>Specs: ASTM A240, ASME SA 240</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct8Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>SHEARED & EDGE-CONDITIONED FLAT BAR</h5>
              <div className="product-info">
                <ul>
                  <li>
                    In grades: 304/L, 316/L .125” ga. thru .500” ga. thickness
                    1” up to 12” wide 84” up to 145” lengths
                  </li>
                  <li>
                    We process custom widths (1”-12”) & lengths (84”-145”)
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct9Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>ANGLES 20'R/L</h5>
              <div className="product-info">
                <ul>
                  <li>.500 to 6” leg and from .125 to .500”</li>
                  <li>In grades: 304/ 304L, 316/316L</li>
                  <li>Specs: QQS – ASTM – ASME – AMS</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct10Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>BEAMS</h5>
              <div className="product-info">
                <ul>
                  <li>3” x 5.7/lb to 12” x 40/lb.</li>
                  <li>In grades: 304/ 304L, 316/316L</li>
                  <li>Specs: QQS – ASTM – ASME</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct11Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>T-BAR</h5>
              <div className="product-info">
                <ul>
                  <li>1” X 1” X .125” to 4” x 4” x .375”</li>
                  <li>In grades: 304/304L, 316/316L</li>
                  <li>Specs: QQS – ASTM – ASME</li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct12Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>ORNAMENTAL TUBING ROUND</h5>
              <div className="product-info">
                <ul>
                  <li>.50” to 5” Diameter.</li>
                  <li>
                    0.049”-0.250” Wall thickness 180,240,320,400,600 and (Polish
                    and Bu )
                  </li>
                  <li>
                    ASTM A- 554 Specification Additional Sizes and Stainless
                    Grades Available Upon Request
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct13Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>ORNAMENTAL TUBING SQUARE</h5>
              <div className="product-info">
                <ul>
                  <li>.50” to 8” Square</li>
                  <li>0.049” -0.375 Wall thickness 180,240, and 320 Grit</li>
                  <li>
                    ASTM A-554 Specification Additional Sizes and Stainless
                    Grades Available Upon Request
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={6} md={3} className="mb-4 mb-lg-5">
              <img src={steelProduct14Url} alt="Accesorios para barandales" />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto"
                alt="Prominox USA"
              />
              <h5>ORNAMENTAL TUBING RECTANGULAR</h5>
              <div className="product-info">
                <ul>
                  <li>.50” to 12”</li>
                  <li>0.049” -0.375 Wall thickness 180,240, and 320 Grit</li>
                  <li>
                    ASTM A-554 Specification Additional Sizes and Stainless
                    Grades Available Upon Request
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div className="carousel--steel__applications-container">
        <div className="carousel-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>

        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--1">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                <span>Cocinas industriales</span>
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--2">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                equipo de refinación petróleo
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--3">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                adornos arquitectónicos
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--4">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                equipos de laboratorio
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div> */}
      <Contact />
    </div>
  );
}
