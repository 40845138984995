import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Contact from "../Contact/Contact";

import "./pipeline.styles.scss";

import tuberiaIconUrl from "../../assets/img-home-tuberia.png";

import { Helmet } from "react-helmet";

import certification1ImgUrl from "../../assets/logo-cfe.jpg";
import certification2ImgUrl from "../../assets/logo-imss.jpg";
import certification3ImgUrl from "../../assets/logo-ance.jpg";
import certification4ImgUrl from "../../assets/logo-ul.jpg";
import certification5ImgUrl from "../../assets/logo-lapem.jpg";

import firstIconUrl from "../../assets/cuerdas-icono.png";
import secondIconUrl from "../../assets/pintura-icono.png";
import thirdIconUrl from "../../assets/rolado-frio-icono.png";
import fourthIconUrl from "../../assets/galvanizado-exterior-icono.png";
// import fifthIconUrl from "../../assets/icn-05.png";
import sixthIconUrl from "../../assets/icn-06.svg";
import seventhIconUrl from "../../assets/calidad-icono.png";
import tuberiaExDelImgUrl from "../../assets/emt-ul2.png";
import tuberiaDelImgUrl from "../../assets/emt-ul62.png";
import tuberiaGruImgUrl from "../../assets/emt-elbow2.png";
// import tuberiaAcoImgUrl from "../../assets/tuberia-conduit-conduit-acometida.jpg";
// import tuberiaCed40ImgUrl from "../../assets/tuberia-conduit-cedula-40.jpg";
// import tuberiaCodoDelImgUrl from "../../assets/tuberia-conduit-codo-pared-delgada.jpg";
// import tuberiaCodoGruImgUrl from "../../assets/tuberia-conduit-codo-pared-gruesa.jpg";
// import tuberiaCopleImgUrl from "../../assets/tuberia-conduit-copledelgadoagrueso.jpg";
// import tuberiaCopleDelImgUrl from "../../assets/tuberia-conduit-cople-pared-delgada.jpg";
// import tuberiaCopleGruImgUrl from "../../assets/tuberia-conduit-cople-pared-gruesa.jpg";
import jupiterImgUrl from "../../assets/Jupiter_Logo.png";
import mexicoMade from "../../assets/hecho_mexico.jpg";
import arrowDownIconUrl from "../../assets/arrow-down.svg";
// import barraCuadradaImgUrl from "../../assets/acero-inoxidable-barra-cuadrada.jpg";
// import antiderrapanteImgUrl from "../../assets/acero-inoxidable-antiderrapante.jpg";
// import anguloImgUrl from "../../assets/acero-inoxidable-angulo.jpg";
// import firstSlideImgUrl from "../../assets/accesorios-barandales-slide-1.png";
// import secondSlideImgUrl from "../../assets/accesorios-barandales-slide-2.png";
// import thirdSlideImgUrl from "../../assets/accesorios-barandales-slide-3.png";

export default function Pipeline() {
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Tubería conduit</title>
        <meta
          name="description"
          content="Contamos con la mejor tubería conduit Júpiter que cunple con todas las normas oficiales mexicanas de fabricación e inspección y con acero de primer calidad"
        />
        <meta
          name="keywords"
          content="tubería conduit, Júpiter, pared gruesa, pared delgada, cédula 40, pared extra delgada, acomedtida, conector"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Tubería conduit" />
        <meta
          itemprop="description"
          content="Contamos con la mejor tubería conduit Júpiter que cunple con todas las normas oficiales mexicanas de fabricación e inspección y con acero de primer calidad"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://prominox-usa.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Tubería conduit" />
        <meta
          property="og:description"
          content="Contamos con la mejor tubería conduit Júpiter que cunple con todas las normas oficiales mexicanas de fabricación e inspección y con acero de primer calidad"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Tubería conduit" />
        <meta
          name="twitter:description"
          content="Contamos con la mejor tubería conduit Júpiter que cunple con todas las normas oficiales mexicanas de fabricación e inspección y con acero de primer calidad"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-pipeline">
          <Container>
            <h1 className="wow fadeInUp">
              GALVANIZED CONDUIT TUBING SPECIALIST
            </h1>
            <img
              src={jupiterImgUrl}
              alt="Jupiter Logo"
              className="mx-auto d-block jupiter wow fadeInUp"
              data-wow-delay=".3s"
            />
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".7s"
            >
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={tuberiaIconUrl} alt="" />
                  <p>Premium Steel</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={tuberiaIconUrl} alt="" />
                  <p>Superior Corrosion Protection</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={tuberiaIconUrl} alt="" />
                  <p>Competitive Price / Local Inventory</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={tuberiaIconUrl} alt="" />
                  <p>State of art production facility</p>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      <section className="certifications">
        <Container>
          <h2 className="wow fadeInUp">
            <span>CERTIFICATIONS</span>
          </h2>
          <div
            className="certifications__logos wow fadeInUp"
            data-wow-delay=".3s"
          >
            <Row>
              <Col xs={12} md="auto" className="mt-3 mt-md-0">
                <img src={certification4ImgUrl} alt="" />
              </Col>
              <Col xs={12} md="auto" className="mt-3 mt-md-0">
                <img src={certification5ImgUrl} alt="" />
              </Col>
              <Col xs={12} md="auto" className="mt-3 mt-md-0">
                <img style={{ height: "90px" }} src={mexicoMade} alt="" />
              </Col>
              <Col xs={12} md="auto">
                <img src={certification1ImgUrl} alt="" />
              </Col>
              {/* <Col xs={12} md="auto" className="mt-3 mt-md-0">
                <img src={certification3ImgUrl} alt="" />
              </Col> */}
            </Row>
          </div>
        </Container>
      </section>
      <section className="pipeline-characteristics">
        <Jumbotron fluid className="jumbotron--hero-pipeline__characteristics">
          <Container>
            <h3 className="wow fadeInUp">QUALITY YOU CAN TRUST</h3>
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".3s"
            >
              <Row className="justify-content-md-center">
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={sixthIconUrl} alt="PROMINOX" />
                    <p>High frequency welding</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={thirdIconUrl} alt="PROMINOX" />
                    <p>Cold roll carbon steel formed tube</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={secondIconUrl} alt="PROMINOX" />
                    <p>Anti-corrosion paint inside</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={firstIconUrl} alt="PROMINOX" />
                    <p>In-line galvanized for superior rust protection</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={fourthIconUrl} alt="PROMINOX" />
                    <p>Free-burr tether</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={seventhIconUrl} alt="PROMINOX" />
                    <p>Premium Steel</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      {/* <section className="carousel--steel-container">
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--steel-item carousel--steel-item--1">
              <div className="carousel--steel-item__heading">
                <h2>características</h2>
              </div>
              <div className="carousel--steel-item__properties">
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Acero de primera calidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={thirdIconUrl} alt="PROMINOX" />
                      <p>Rolado en frío</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={secondIconUrl} alt="PROMINOX" />
                      <p>Pintura anticorrosiva en el interior</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Cuerdas libres de rebaba</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Galvanizado exterior</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Soldadura de alta frecuencia</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--2">
            <div className="carousel--steel-item carousel--steel-item--2">
              <div className="carousel--steel-item__heading">
                <h2>austeníticos</h2>
                <h3>aleaciones comunes</h3>
                <div>
                  <h3>201</h3>
                  <h3>301</h3>
                  <h3>304</h3>
                  <h3>310</h3>
                  <h3>316</h3>
                </div>
              </div>
              <div className="carousel--steel-item__properties">
                <h4>propiedades</h4>
                <Row>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={sixthIconUrl} alt="PROMINOX" />
                      <p>Excelente soldabilidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={fifthIconUrl} alt="PROMINOX" />
                      <p>Gran higiene y limpieza</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={fourthIconUrl} alt="PROMINOX" />
                      <p>Fácil dilatación y contracción</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section> */}
      <section className="steel-lineup pipeline-lineup">
        <Container>
          <h4 className="wow fadeInUp">our line products</h4>
          <Row className=" mt-3 mt-md-0" data-wow-delay=".3s">
            <Col xs={12} md={4} className="mb-3 mb-md-0 text-left ">
              <img
                src={tuberiaExDelImgUrl}
                // className="img-fluid"
                alt="Tubería Conduit"
              />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px" }}
              />
              <h5 className="w-sm">Conduit EMT UL 797</h5>
            </Col>
            <Col
              xs={12}
              md={4}
              className="mb-3 text-left text-md-start pl-sm-0 pl-lg-3"
            >
              <img
                src={tuberiaDelImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px" }}
              />
              <h5 className="w-sm">Conduit ERMC UL6 "RIGID"</h5>
            </Col>
            <Col xs={12} md={4} className="mb-3 mb-md-0 text-left ">
              <img
                src={tuberiaGruImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
              <img
                src={arrowDownIconUrl}
                className="d-block mx-auto mb-4"
                alt="Prominox USA"
                style={{ height: "35px", margin: "1.5rem 0" }}
              />
              <h5 className="w-sm">EMT ELBOWS</h5>
            </Col>
            {/* <Col
              xs={6}
              md={3}
              className="mb-3  text-left text-md-right pl-sm-0 pl-lg-3"
            >
              <img
                src={tuberiaAcoImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0 text-left text-md-right">
              <img
                src={tuberiaCed40ImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="mb-3  text-left text-md-start pl-sm-0 pl-lg-3"
            >
              <img
                src={tuberiaCodoDelImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3  text-left text-md-start">
              <img
                src={tuberiaCodoGruImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="mb-3  text-left text-md-start pl-sm-0 pl-lg-3"
            >
              <img
                src={tuberiaCopleImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3  text-left text-md-start">
              <img
                src={tuberiaCopleDelImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="mb-3  text-left text-md-start pl-sm-0 pl-lg-3"
            >
              <img
                src={tuberiaCopleGruImgUrl}
                className="img-fluid"
                alt="Tubería Conduit"
              />
            </Col> */}
          </Row>
          {/* <Row className="my-0 my-md-4 wow fadeInUp" data-wow-delay=".3s">
          </Row>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
          </Row> */}
        </Container>
      </section>
      <div className="carousel--pipeline__applications-container">
        <div className="carousel-caption--top">
          <h5 className="wow fadeInUp">APPLICATIONS</h5>
        </div>
        <div
          className="carousel-caption--bottom wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div>
            <p>
              Manufactured under the ASTM-A-569, ASTM-A-366, ASTM B6 standard
              with premium steel through a galvanizing process.
            </p>
          </div>
        </div>
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--pipeline__applications__item carousel--pipeline__applications__item--1"></div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--pipeline__applications__item carousel--pipeline__applications__item--2">
              {/* <div className="carousel-caption--bottom">
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--pipeline__applications__item carousel--pipeline__applications__item--3">
              {/* <div className="carousel-caption--bottom">
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--pipeline__applications__item carousel--pipeline__applications__item--4">
              {/* <div className="carousel-caption--bottom">
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <Contact />
    </div>
  );
}
