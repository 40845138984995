import gql from "graphql-tag";

const CATEGORY_ARTICLES_QUERY = gql`
  query Category($id: ID!) {
    categories(where: { id: $id }) {
      id
      name
      imagen {
        url
      }
      articles {
        id
        title
        content
        description
        imagen {
          url
        }
        category {
          id
          name
        }
      }
    }
  }
`;

export default CATEGORY_ARTICLES_QUERY;
