import React from "react";

// import AluminumVideo from "../../assets/videos/ALUMINIO.mp4";

import "./video.styles.scss";

export default function Video() {
  return (
    <div>
      <div style={{ height: "800px", position: "relative" }}>
        {/* <div
        // style={{
        //   position: "absolute",
        //   width: "100%",
        //   left: "50%",
        //   top: "50%",
        //   height: "100%",
        //   objectFit: "cover",
        //   transform: "translate(-50%, -50%)",
        //   zIndex: "-1",
        // }}
        >
        </div> */}
        {/* <video autoPlay loop muted className="video">
          <source src={AluminumVideo} type="video/mp4" />
        </video> */}
        <div className="content">
          <div className="subContent">
            <h1>hola</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore,
              quis?
            </p>
          </div>
        </div>
      </div>
      <div>
        <p>hola</p>
      </div>
    </div>
  );
}
