import React from "react";
import { useParams } from "react-router";
import "./category.styles.scss";

// Components
import Articles from "../../components/Articles";
import { CategoryJumbotron } from "../../components/Jumbotron/Jumbotron";
import Query from "../../components/Query";
import Contact from "../../components/Contact/Contact";

// Queries
import CATEGORY_ARTICLES_QUERY from "../../queries/category/articles";

// Bootstrap
import Container from "react-bootstrap/Container";

const Category = () => {
  let { id } = useParams();
  return (
    <Query query={CATEGORY_ARTICLES_QUERY} id={id}>
      {({ data: { categories } }) => {
        console.log(categories);
        return (
          <div className="category-wrapper">
            <CategoryJumbotron
              title={categories[0].name}
              bgImage={categories[0].imagen}
            />
            <div className="category">
              <div className="category-container">
                {categories[0].articles.length > 0 ? (
                  <Container fluid>
                    <Articles articles={categories[0].articles} />
                  </Container>
                ) : (
                  <Container className="py-4">
                    <p>No hay artículos en esta categoría.</p>
                  </Container>
                )}
              </div>
            </div>
            <Contact />
          </div>
        );
      }}
    </Query>
  );
};

export default Category;
