import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import "./footer.styles.scss";

import fbIconUrl from "../../assets/fb-icon--white.svg";
import linkedinIconUrl from "../../assets/linkedin-icon--white.svg";
import igIconUrl from "../../assets/ig-icon--white.svg";
import prominoxLogoWhiteUrl from "../../assets/prominox-logo--white.png";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} lg={3}>
            <div className="footer__l">
              <Link to="/">Home</Link>
              <a href="#contact" className="my-3">
                request to quote
              </a>
              <Link to="/contact-us-dallas">Contact - dallas</Link>
              <Link to="/contact-us-houston" className="my-3">
                Contact - houston
              </Link>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="footer__m">
              <p>
                2024 prominox usa |{" "}
                <Link to="/aviso-de-privacidad">notice of privacy</Link>
              </p>
              {/* <Link to="/politicas-de-devolucion">política de devolución</Link> */}
              <div className="footer__m__social">
                <a
                  href="https://www.facebook.com/prominoxusa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fbIconUrl} alt="Redes sociales prominox" />
                </a>
                <a
                  href="https://www.linkedin.com/company/prominoxusa"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <img src={linkedinIconUrl} alt="Redes sociales prominox" />
                </a>
                <a
                  href="https://www.instagram.com/prominoxusa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={igIconUrl} alt="Redes sociales prominox" />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="footer__r">
              <img src={prominoxLogoWhiteUrl} alt="PROMINOX" />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
